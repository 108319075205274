<template>
  <div>
    <x-toolbar
      v-if="!isComponent"
      :title="pageTitle"
    />
    <v-card>
      <v-container fluid>
        <v-flex
          xs12
        >
          <v-card-title
            v-if="isComponent"
            class="subheading"
          >
            {{ $t('manufacturers.create.label') }}
            <v-spacer />
            <v-icon
              @click="close"
            >
              $closeItem
            </v-icon>
          </v-card-title>
        </v-flex>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent
        >
          <FormFields
            ref="fields"
            :form="form"
            :render="formRender"
            lang-path="manufacturers."
          />
          <v-btn
            :text="!valid"
            :loading="loading"
            type="submit"
            color="accent"
            @click="submit()"
          >
            <v-icon
              class="mr-2"
            >
              $saveItem
            </v-icon>
            {{ $t('form.save') }}
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {tabTitle} from "@/utils/string";
    import {ManufacturerAPI} from "@/api/ManufacturerAPI";
    import {EventBus} from "@/service/EventBus";
    import {ManufacturerForm, ManufacturersRender} from "@/app/manufacturers/definitions/manufacturer.form";
    import FormFields from "@/app/components/form/FormFields.component";
    import {setFormErrors} from "@/utils/form";
    import {FormFetchItemMixin} from "@/app/mixins/FormFetchItemMixin";
    import {InstanceCacheMixin} from "@/app/mixins/InstanceCacheMixin";
    import {getIdFromLocation} from "@/utils/url";

    export default {
        name: "ManufacturerCreateUpdate",
        components: {FormFields},
        mixins: [FormFetchItemMixin, InstanceCacheMixin, RouteParamsMapperMixin],
        props: {
            isEdit: {
                type: Boolean,
                default: false
            },
            isComponent: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            form: new ManufacturerForm,
            formRender: new ManufacturersRender,
            valid: true,
            loading: false
        }),
        computed: {
            pageTitle: function () {
                let title = this.$t(this.$route.meta.title);
                if (this.isEdit && this.form.abbreviation !== '') {
                    title = this.$t('manufacturers.update.title', [this.form.abbreviation]);
                }
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            }
        },
        methods: {
            formFetchItem: function () {
                return ManufacturerAPI.get(this.manufacturerId);
            },
            submit: function () {
                if (!this.valid) {
                    this.$refs.form.validate();
                    return;
                }
                this.loading = true;
                if (this.isEdit) {
                    const id = this.manufacturerId;
                    ManufacturerAPI.update(id, this.form)
                        .then(() => {
                            this.advancedSnack({
                                text: 'manufacturers.update.done',
                                params: [this.form.abbreviation],
                                timeout: -1,
                                undo: ManufacturerAPI.undoUpdateFn(id, () => {
                                    EventBus.$emit('manufacturers-list-reload');
                                    this.advancedSnack({
                                        text: 'manufacturers.update.undo.done',
                                        params: [this.form.abbreviation]
                                    });
                                }, () => {
                                    this.advancedSnack({
                                        text: 'manufacturers.update.undo.failed',
                                        timeout: -1
                                    });
                                })
                            });
                            this.clearInstanceCachesByManufacturer(id);
                            this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                            this.$router.push('/manufacturers');
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    ManufacturerAPI.create(this.form)
                        .then(response => {
                            this.advancedSnack({
                                text: 'manufacturers.create.done',
                                params: [this.form.abbreviation],
                                timeout: -1,
                                undo: ManufacturerAPI.undoCreateFn(getIdFromLocation(response), () => {
                                    EventBus.$emit('manufacturers-list-reload');
                                    this.advancedSnack({
                                        text: 'manufacturers.create.undo.done',
                                        params: [this.form.abbreviation]
                                    });
                                }, () => {
                                    this.advancedSnack({
                                        text: 'manufacturers.create.undo.failed',
                                        timeout: -1
                                    });
                                })
                            });
                            if (this.isComponent) {
                                EventBus.$emit('create-manufacturer-created', getIdFromLocation(response));
                            } else {
                                this.$route.meta.uuid = this.$route.meta.uuid + 1 || 1;
                                this.$router.push('/manufacturers');
                            }
                        }).catch(setFormErrors.bind(this))
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            close: function () {
                EventBus.$emit('create-manufacturer-cancelled');
            }
        }
    };
</script>

<style scoped>

</style>

import {StockLocationLockType} from "@/enum/stock_location_lock_type";
import {PreparePackageMode} from "@/enum/prepare_package_mode";

const stockOrSubStockOrLocationNotFound = "A megadott raktár, alraktár vagy helyszín nem létezik!";

export default {
    title: "Raktár",
    link: "Raktárak",

    name: "Raktár neve",
    id: "Raktár ID",
    description: "Leírás",
    is_small: "Kis raktár",
    address: "Cím",

    chief: "Ügyvezető",
    chief_id: "Ügyvezető",

    locations: {
        label: "Raktárhelyszín",
        name: "Helyszín neve",
        code: "Kód",
        availability: "Elérhetőség",
        availability_hint: "A magasabb szám jobb rendelkezésre állást jelent",
        setAvailability: "Elérhetőség beállítása",
        availabilityBatch: {
            failed: "Nem sikerült beállítani a rendelkezésre állást az összes helyszínre!",
            done: "Elérhetőségi készlet."
        },
        is_expedition: "Expediál",
        expedition: "Expedíció",
        is_mobile: "Mobilis",
        mobil: "Mobilis",
        nonMobile: "Nem mobilis",
        codehint: "Automatikus generáláshoz hagyja üresen",
        none: "<nincs elhelyezve>",
        at: "elhelyezendő",
        is_allowed: "Engedélyezve ehhez az alraktárhoz",
        stockStatus: "Helységenkénti tételek",
        searchHint: "Keresi a hely nevét és kódját",

        printBarcode: "Címke nyomtatása",
        printBarcodeMany: "Címkék nyomtatása",
        printBarcodeHowMuch: "Hány címkét '{0}' kell kinyomtatni?",
        printBarcodesHowMuch: "Hány címkét kell kinyomtatni?",
        printDone: "'{0}' címke nyomtatása...",

        items: {
            productsAtSubstock: "Termékek a kiválasztott helyen",
            noItems: "A kiválasztott helyen nincs semmi!"
        },

        locks: {
            strict_mode: "Szigorú",
            strictHint: "A szigorú zár lehetővé teszi, hogy a termékeket csak azokon a helyeken helyezze el, ahol le vannak zárva.",
            note: "Megjegyzés",
            lockTypes: {
                title: "Ez a hely a {0} korlátozódik",

                [StockLocationLockType.MANUFACTURER]: "Gyártóra",
                [StockLocationLockType.PRODUCT_TYPE]: "Készlet típusára",
                [StockLocationLockType.PRODUCT]: "Készletelemre",
                [StockLocationLockType.INSTANCE]: "Különleges készletelemhez",
            },
            lockItems: {
                title: "Korlátozza a helyszínt",

                [StockLocationLockType.MANUFACTURER]: "Gyártó",
                [StockLocationLockType.PRODUCT_TYPE]: "A készletelem típusa",
                [StockLocationLockType.PRODUCT]: "Készletelem",
                [StockLocationLockType.INSTANCE]: "Különleges készletelem",
            },

            newType: "Az új zár típusa",
            removeType: "Zárja be {0}",

            removeLock: "Távolítsa el a zárat",
            unableToRemoveLastLock: "Nem lehet eltávolítani az utolsó zárat!",
            lockAlreadySelected: "Ez a zár már ki van választva!",
            allTypesExist: "Ez a hely már minden lehetséges típusra korlátozódik!",

            create: {
                done: "Létrejött a {0} zárolása.",
                titleSimple: "Zár hozzáadása"
            },
            update: {
                done: "Zárolás {0} módosítva."
            },
            delete: {
                done: "A {0} zárolása törölve."
            },

            status: {
                404: "A megadott raktár, helyszín vagy zár típus nem létezik.!",
                409: "Helytelen zár típusa!"
            }
        },

        show: {
            titleSimple: "Helyszín",
            title: "A '{0}' elhelyezése a '{1}'-ben",
            label: "Helyszínelemek"
        },
        create: {
            titleSimple: "Új helyszín",
            title: "Új helyszín {0}",
            label: "Új hely létrehozása",
            done: "Létrejött a '{0}' hely."
        },
        update: {
            titleSimple: "Helyszín szerkesztése",
            title: "Szerkeszd a '{0}' helyet {1}-ben",
            done: "A '{0}' helyét módosították."
        },
        delete: {
            done: "'{0}' hely törölve.",
            failed: "A '{0}' helyet nem sikerült törölni. Kérjük, próbálja meg később újra.",
            status: {
                404: "A megadott áruraktár vagy hely nem létezik!",
                409: "A hely nem törölhető más objektumokkal való kapcsolatai miatt!"
            }
        },

        status: {
            400: "Helytelen dátum formátum!",
            404: "A megadott raktár vagy hely nem létezik!",
            409: "A megadott kód már egy másik helyen használatban van!"
        }
    },

    substocks: {
        label: "Alraktár",
        id: 'Subwarehouse ID',
        stock_id: 'Raktár neve',
        name: "Alraktár neve",
        description: "Leírás",
        noSubstocks: "Nincs alraktárak",
        owner: {
            name: "Tulajdonos"
        },
        owner_id: "Tulajdonos",
        has_permission: "rendelkezik engedéllyel",
        prepare_package_mode: "Szállítmánycsomagolási mód",
        order_with_reservation: "Megrendelt tételek lefoglalása",
        auto_merge_stock_pickings: "A hasonló választások automatikus egyesítése",
        auto_merge_stock_pickings_hint: "Nem érinti a rendelésekből létrehozott kiszedéseket.",
        allow_multiple_shipment_label_prints: "Több címke nyomtatásának engedélyezése egy megrendeléshez",
        allow_multiple_shipment_label_prints_hint: "A menedzser bármikor nyomtathat több címkét.",
        mark_as_delivered_after_packing: "Automatikusan szállítson személyes gyűjtést a csomagolás után",
        mark_as_delivered_after_packing_hint: "A \"személyes átvétel\" rendelések küldeményeit a csomagolást követően azonnal kézbesítettnek jelöljük.",

        items: {
            productsAtSubstock: "Termékek a kiválasztott alraktárban",
            noItems: "A kiválasztott alraktárban nem találtunk árut!"
        },

        show: {
            titleSimple: "Alraktár",
            title: "Alraktár '{0}' az '{1}'-ben"
        },
        create: {
            titleSimple: "Új álraktár",
            title: "Új almappa {0}",
            label: "Hozzon létre egy új almappát",
            done: "Áthelyezés'{0}' létrehozva.",
            addedPermission: "'{0}' most már hozzáférhet '{1}'-hez. Ajánlott más felhasználók számára is beállítani a hozzáférést."
        },
        update: {
            titleSimple: "Alraktár szerkesztése",
            title: "A '{0}' alraktár szerkesztése az '{1}' alraktárba",
            done: "'{0}' alraktár módosítva."
        },
        delete: {
            done: "'{0}' alraktár törölve.",
            failed: "A '{0}' alraktár nem törölhető. Kérjük, próbálja meg később újra.",
            status: {
                404: "A megadott áruház vagy annak aláruháza nem létezik!",
                409: "Az alraktár nem törölhető más objektumokkal való kapcsolatai miatt!"
            }
        },

        status: {
            404: "A megadott raktár vagy annak al ágazata nem létezik!",
            409: "Az alraktár nem törölhető más objektumokkal való kapcsolatai miatt!"
        },

        allowedLocations: {
            status: {
                404: "A megadott raktár, annak alraktára vagy adott raktári tétel nem létezik!"
            }
        },

        allowedInstances: {
            status: {
                404: stockOrSubStockOrLocationNotFound
            }
        },

        whitelist: {
            list: "'{0}' Engedélyezett helyek listája",
            available: "A '{0}' számára engedélyezhető helyek",
            auto: "Automatikusan engedélyezve",

            create: {
                title: "Hozzáadás az engedélyezett helyek listájához",
                done: "Location '{0}' engedélyezve",
                failed: "Nem sikerült engedélyezni '{0}'"
            },
            delete: {
                title: "Eltávolítás az engedélyezett helyek listájáról",
                done: "Áthelyezés '{0}' tiltva",
                failed: "Nem sikerült letiltani a '{0}' helyet",
                status: {
                    404: stockOrSubStockOrLocationNotFound,
                    409: "A helyszín nem távolítható el a alraktárból, mert az áru benne található!",
                }
            },
            batch: {
                true: "Szűrés engedélyezése",
                false: "Szűrt engedélyek eltávolítása",
                checked: {
                    true: "Hozzáadás a fehér listához",
                    false: "Eltávolítás a fehérlistáról",
                },
                done: {
                    true: "Hely engedélyezett",
                    false: "Engedély visszavonva",
                },
                failed: "Az engedély módosítása sikertelen!"
            },
            status: {
                404: "A megadott raktár, alraktár vagy helyszín nem létezik!",
                409: "A kiválasztott hely már engedélyezve van ebben az alraktárban!"
            }
        },

        prepare_package_mode_items: {
            [PreparePackageMode.EAN_READ_EACH]: "Minden tételhez hangjelzés szükséges",
            [PreparePackageMode.SELECT_EACH]: "Engedélyezze az egyes elemek manuális kiválasztását",
            [PreparePackageMode.SELECT_FIRST_AUTO_PACK_REST]: "Engedélyezze az első tétel manuális kiválasztását, majd automatikusan csomagolja be a rendelésből származó összeset"
        },

        task_settings: {
            label: "Feladat konfiguráció",
            autoApprove: "Elvégzett feladatok automatikus jóváhagyása",
            autoPrintReport: "Jelentések automatikus nyomtatása a feladat befejezésekor",
            sortTaskItemsByLoaded: 'Rendezze a feladatok elhelyezését a legrégebbitől a legrégebbiig',
            updateDone: "Beállítás módosítva",
            status: {
                404: "A megadott almappa nem létezik!"
            }
        },

        task_attributes: {
            label: "Feladat attribútumok konfigurálása",
            forSubstock: "Engedélyezett attribútumok minden egyes feladattípushoz ebben az alfában",
            forStock: "Engedélyezett attribútumok minden feladattípushoz a raktárban",
            forStockHint: "Egyes feladattípusok esetében a részkészlet nincs megadva, így az attribútumok a teljes készletre vannak beállítva.",
            updateDone: "Beállítás módosítva",
        },

        order_processing: {
            label: "Automatikus termék összevonás",
            runNow: "Azonnal kezdje meg az összevont komissiózás létrehozását a megrendelésekből",
            enable_automatic_triggering: "Automatikusan futtatás",
            max_automatic_sets: "Maximum egyidejű csoportok",
            min_orders_per_automatic_set: "Minimális tételfelvételi feladatok egy cikkkomissiózó csoportban",
            max_orders_per_automatic_set: "Maximális tételfelvételi feladatok egy cikkkomissiózó csoportban",
            strategies: "Összevont szedési stratégia",
            strategyPriority: "A stratégiák prioritása",
            strategy: {
                SAME_SINGLE_PRODUCT: "Ugyanaz az egy tétel",
                SINGLE_PRODUCT: "Egyetlen tétel",
                IDENTICAL_ORDERS: "Ugyanaz",
                FROM_OLDEST: "A legrégebbitől"
            },
            updateDone: "Módosított beállítások",
            updateDoneWithDisabling: "Módosították a beállításokat, és letiltották az automatikus egyesítést",
            activationDone: "Automatikus egyesítés aktiválva",
            deactivationDone: "Az automatikus egyesítés letiltva",
            run: {
                status: {
                    204: "A munka feldolgozásra vár",
                    404: "A megadott raktár vagy alraktár nem létezik!",
                }
            },
            status: {
                404: "A megadott raktár vagy annak alraktára nem létezik!",
                409: "Érvénytelen rendelésfeldolgozási konfiguráció!",
            },
            active: "Az automatikus egyesítés aktiválva van",
            deactivated: "Az automatikus egyesítés le van tiltva",
            tooltipActivationButtonDeactivated: "Az aktiváláshoz legalább egy stratégiát ki kell választani, és az alábbi konfigurációban minden értéknek pozitív egész számnak kell lennie. Mindent meg kell menteni.",
            tooltipActivationButtonCanNotBeChanged: "Váltás előtt el kell menteni a változtatásokat az alábbi gombbal.",
        },
        buyer_types: {
            label: "Hozzárendelt vevőtípusok",
        },
    },

    owners: {
        name: "Név",
        ico: "ICO",
        dic: "Adószám",
        phone: "Telefon",
        email: "Email",
        website: "Weboldal",
        billing_address: "Számlázási cím",
        bank_account: "Számlaszám",

        unableToLoad: "A tulajdonos nem tudott betöltődni. Kérjük, próbálja meg újra",

        link: "Almappák tulajdonosai",

        list: {
            title: "Alraktár tulajdonosok"
        },
        show: {
            title: "Alrakár tulajdonosa",
        },
        create: {
            label: "Új tulajdonos létrehozása",
            titleSimple: "Új alraktár tulajdonos",
            title: "A '{0}' alraktár új tulajdonosa",
            done: "Tulajdonos '{0}' létrehozva."
        },
        update: {
            titleSimple: "Tulajdonos szerkesztése",
            title: "Tulajdonos szerkesztése '{0}'",
            done: "Tulajdonos '{0}' módosítva."
        },
        delete: {
            done: "Tulajdonos '{0}' törölve!",
            failed: "A '{0}' tulajdonos nem törölhető. Kérjük, próbálja meg később újra.",
            status: {
                404: "A megadott almappa tulajdonosa nem létezik!",
                409: "Az almappa tulajdonosa nem törölhető más objektumokkal való kapcsolatai miatt!",
            }
        },

        status: {
            404: "A megadott aláruház tulajdonosa nem létezik!",
            409: "Egy másik raktártulajdonosnak már megvan az elkészült azonosító!",
        }
    },

    stockChoose: {
        form: {
            stockId: "Válasszon ki egy raktárat",
            subStockId: "Válasszon ki egy alraktárat",
            locationId: "Csak helyszín...",
            productId: "Csak raktárkészleten lévő termék ...",
            instanceId: "Csak egy meghatározott raktári cikk ...",
        }
    },

    stockStatus: {
        link: "Készletállapot",
        title: "A készletek állapotának áttekintése",
        allSubstocks: "<minden alraktár>",
        allLocations: "<minden helyen raktáron>",
        allLocationsIn: "<a '{0}' összes helye>",
        allProducts: "<minden elem>",
        optional: "Választható lehetőségek",
        table: {
            stock: {
                id: "Raktár",
                choose: "Válasszon ki egy raktárat"
            },
            product_instance: {
                id: "Tétel neve",
                choose: "Válasszon ki egy terméket"
            },
            stock_location: {
                id: "Helyszín"
            },
            quantity: "Mennyiség",
            last_change: "Utolsó változtatás",
            loaded_at: "A legrégebbi tétel"
        },
        showItem: "Elem megjelenítése",
        showLocation: "Helyszín megjelenítése",

        locations: {
            link: "Helyek jelentés",
            title: "Napi helyhasználati jelentés",
            generating: "Jelentés készül...",
            form: {
                stockId: "Raktár",
                subStockId: "Alraktárak",
                dateFrom: "tól",
                dateTo: "g",
            },
            table: {
                location_id: "Helyazonosító",
                location_name: "Helynév",
                days_used: "Használt napok száma"
            }
        },

        buyPrices: {
            name: "A beszerzési árak exportja",
            title: {
                IN: "A beszerzési árak exportja - bevételezés",
                CURR: "A beszerzési árak exportja - készlet állapota",
                OUT: "A beszerzési árak exportja - felvétel"
            },
            exportTitle: "Vásárlási ár - készlet állapota",
            newLocation: "A beszerzési árjelentés mostantól az 'Exportálás' fülön találhatók.",

            stockId: "Raktár",
            subStockId: "Alraktárak",
            dateTo: "g",
            date_from: "from",
            date_to: "g",

            status: {
                409: "Érvénytelen dátum"
            }
        }
    },

    stockMovements: {
        link: "Készlet áthelyezések",
        title: "A készletmozgások áttekintése",
        table: {
            type: "Mozgás típusa",
            product_instance: {
                id: "Termék"
            },
            task: {
                id: "Feladat",
                type: "Feladattípus",
            },
            substock: {
                id: 'Alraktárak',
            },
            location: {
                id: 'Hely',
                type: 'Hely típusa',
                STOCK: 'Raktár',
                USER: 'Raktáros'
            },
            movement: {
                PUT: 'IN',
                PICK: 'OUT'
            },
            amount: "Mennyiség",
            created_at: "Dátum",
            created_by: "Felhasználó",
        },

    },

    list: {
        unableToLoad: "A raktárak listáját nem sikerült betölteni. Kérjük, próbálja meg később újra.",
    },
    show: {
        titleSimple: "Részletes készlet",
        title: "{0}"
    },
    create: {
        title: "Új raktár",
        done: "Létrejött a '{0}' raktár."
    },
    update: {
        titleSimple: "Csoportos szerkesztés",
        title: "Szerkesztés {0}",
        done: "A '{0}' raktár módosítva."
    },
    delete: {
        done: "'{0}' készlet törölve.",
        failed: "A '{0}' raktár nem törölhető. Kérjük, próbálja meg később újra."
    },

    status: {
        400: "Helytelen dátumformátum!",
        404: "A megadott raktár nem létezik!",
        409: "A raktár más objektumokkal való kapcsolatai miatt nem törölhető!",
        423: "A jelentés még nem készült el. Kérjük, próbálja meg ezt a műveletet később."
    }
};

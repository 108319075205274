<template>
  <div>
    <x-toolbar
      :title="pageTitle"
      :tabs="hasTabs"
      :full-width="true"
      :extension="hasTabs"
    >
      <template
        v-if="hasTabs"
        #extension
      >
        <v-tabs
          v-model="tab"
          background-color="primary lighten-1"
          grow
        >
          <v-tabs-slider
            color="accent"
          />
          <v-tab
            v-for="item of tabItems"
            :key="item.label"
          >
            {{ $t('tasks.externalOrder.navigation.' + item.label) }}
          </v-tab>
        </v-tabs>
      </template>
    </x-toolbar>
    <v-dialog
      v-model="changeProcessingModeDialog"
      width="800"
      :overlay-opacity="0.9"
    >
      <ExternalOrderProcessingModePicker
        :task-info="taskInfo"
        change-required
        @processing-mode-changed="handleProcessingModeChange"
      />
    </v-dialog>
    <v-alert
      v-if="hasCarrierErrors"
      type="error"
    >
      <span>
        {{ $t('tasks.preparePackage.shipments.errorMessages') + ': ' }}
      </span>
      {{ (details.carrier_api_error_messages || []).join(', ') }}
    </v-alert>
    <v-alert
      v-if="taskInfo && taskInfo.details && isProcessTogether
        && !allItemsAvailable"
      type="warning"
    >
      <span>
        {{ $t('tasks.externalOrder.someItemsNotAvailable') }}
      </span>
    </v-alert>
    <template v-if="details">
      <template v-if="assignableWithModeChange">
        <TaskStartActionButton
          :loading="loading"
          @continue="onAssign"
        />
      </template>
      <TaskConflict
        v-if="conflict"
        :error="conflict"
      />
      <template v-if="hasTabs">
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item of tabItems"
            :key="item.label"
            eager
          >
            <component
              :is="item.component"
              :task-info="taskInfo"
              :items="items"
              :all-items-available="allItemsAvailable"
            />
          </v-tab-item>
        </v-tabs-items>
      </template>
      <template v-else>
        <ExternalOrderAssignment
          :task-info="taskInfo"
          :items="items"
        />
      </template>
    </template>
    <template v-else>
      <v-progress-linear
        indeterminate
      />
    </template>
  </div>
</template>


<script>
    import {BuyerAPI} from "@/api/BuyerAPI";
    import {TaskExternalOrderAPI as API} from "@/api/TaskExternalOrderAPI";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import TaskStartActionButton from "@/app/tasks/components/TaskStartActionButton.vue";
    import {taskNames, taskTypes} from "@/enum/task_type";
    import {assign} from "@/utils/object";
    import {tabTitle} from "@/utils/string";
    import ExternalOrderAssignment from "@/app/tasks/externalOrder/components/ExternalOrderAssignment.component";
    import ExternalOrderOrder from "@/app/tasks/externalOrder/components/ExternalOrderOrder.component";
    import ExternalOrderOverview from "@/app/tasks/externalOrder/components/ExternalOrderOverview.component";
    import {TaskMoveProductsType} from "@/enum/task_move_products_type";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {BarcodeListenerMixin} from "@/app/mixins/BarcodeListenerMixin";
    import {EventsListenerMixin} from "@/app/mixins/EventsListenerMixin";
    import ExternalOrderProcessingModePicker from "@/app/tasks/externalOrder/components/ExternalOrderProcessingModePicker";
    import {TaskExternalOrderProcessingModeMixin} from "@/app/mixins/TaskExternalOrderProcessingModeMixin";
    import TaskConflict from "@/app/tasks/components/TaskConflict.component.vue";

    export default {
        name: "ExternalOrderShow",
        components: {TaskConflict, TaskStartActionButton, ExternalOrderAssignment, ExternalOrderProcessingModePicker},
        mixins: [
            ACLMixin,
            TaskStateMixin,
            TaskAssignMixin,
            TaskFetchItemsMixin,
            RouteParamsMapperMixin,
            BarcodeListenerMixin,
            EventsListenerMixin,
            TaskExternalOrderProcessingModeMixin
        ],
        data: () => ({
            API: API,
            loading: false,
            type: taskTypes.EXTERNAL_ORDER,
            details: null,
            items: [],
            tab: null,
            changeProcessingModeDialog: false,
        }),
        computed: {
            typeName: function () {
                return this.$t(taskNames[this.type]);
            },
            events: function () {
                return {
                    'fetch-items': this.onFetchItems,
                    'onBarcodeRead': this.handleBarcodeRead,
                    'show-items': this.showItems
                };
            },
            pageTitle: function () {
                const title = '#' + this.taskId + ' ' + this.typeName;
                if (!this._inactive) {
                    document.title = tabTitle(title);
                }
                return title;
            },
            taskInfo: function () {
                return {
                    details: this.details,
                    tab: this.tab,
                    taskId: this.taskId,
                    movementType: TaskMoveProductsType.COLLAPSE
                };
            },
            tabItems: function () {
                return [{
                    label: 'assignment',
                    component: ExternalOrderAssignment
                }]
                    .concat([{
                        label: 'order',
                        component: ExternalOrderOrder
                    }])
                    .concat(
                        (this.isChief && this.isOpenState)
                            ? [{
                                label: 'finish',
                                component: ExternalOrderOverview
                            }] : []); // TODO refactor these in all tasks
            },
            hasTabs: function () {
                return this.tabItems.length > 1;
            },
            hasCarrierErrors: function () {
                return this.details && this.details.carrier_api_error_messages && this.details.carrier_api_error_messages.length > 0;
            },
            assignableWithModeChange: function () {
                return !this.assignedToAnybody && this.isAssignableState && this.isChief;
            },
            /**
             * @override TaskAssignMixin
             */
            assignable: function () {
                return this.assignableWithModeChange && !this.isDoNotProcess;
            },
            allItemsAvailable: function () {
                return !this.items.some(item => item.quantity - item.reserved_quantity - item.satisfied_quantity > (item.available || 0));
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            this.getTask(lifeCycleHook)
                .then(() => {
                    if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                        this.fetchItems({initial: true});
                    } else {
                        this.fetchItems().catch(this.snack);
                    }
                }).catch(this.snack);
        },
        methods: {
            getTask: function (lifeCycleHook) {
                return API.get(this.taskId, true)
                    .then(response => {
                        if (this.details === null || lifeCycleHook === this.LifeCycleHook.CREATED) {
                            this.details = response.data;
                        } else {
                            assign(this.details, response.data);
                        }
                        BuyerAPI.get(this.details.buyer_id)
                            .then(response => {
                                this.$set(this.details, 'buyer', response.data);
                            });
                    }).catch(err => {
                        this.snack(err);
                        this.$router.push('/');
                    });
            },
            handleBarcodeRead: function (data) {
                this.activateTab(data);
                if (this.isDoNotProcess) {
                    this.changeProcessingModeDialog = true;
                }
            },
            onAssign: function () {
                if (this.isDoNotProcess) {
                    this.changeProcessingModeDialog = true;
                } else {
                    this.assign();
                }
            },
            handleProcessingModeChange: function () {
                this.changeProcessingModeDialog = false;
                if (this.assignableWithModeChange) {
                    this.assign();
                }
            },
            showItems: function () {
                this.tab = 1;
            }
        }
    };
</script>

<style scoped>

</style>

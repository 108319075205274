<template>
  <v-dialog
    v-model="dialog"
    width="600"
    :overlay-opacity="0.9"
  >
    <v-card elevation="8">
      <v-card-title class="auto-logout-title">
        <v-icon class="mr-4">
          $warning
        </v-icon>
        <span>
          {{ $t("base.autoLogout.dialog.title") }}
        </span>
      </v-card-title>
      <v-card-text class="pt-4">
        <span>
          {{ autoLogoutWarningTimeoutText }}
        </span>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="accent"
          elevation="0"
          @click="dialog = false"
        >
          {{ $t("base.autoLogout.dialog.continue") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import { AutoLogoutService } from "@/service/AutoLogout";
    import { EventsListenerMixin } from "@/app/mixins/EventsListenerMixin";

    export default {
        name: "AutoLogout",
        mixins: [EventsListenerMixin],
        data: () => ({
            domEvents: [
                "mousemove",
                "mousedown",
                "click",
                "scroll",
                "keypress",
                "resize",
                "touchstart",
                "touchmove"
            ],
            warningTimeout: null,
            logoutTimeout: null,
            countdownInterval: null,
            remainingTime: null,
            dialog: false,
        }),
        computed: {
            autoLogout() {
                return this.$store.getters["userConfig/autoLogout"];
            },
            autoLogoutTimeout() {
                return this.$store.getters["userConfig/autoLogoutTimeout"];
            },
            autoLogoutWarning() {
                return this.$store.getters["userConfig/autoLogoutWarning"];
            },
            autoLogoutWarningTimeout() {
                return this.$store.getters["userConfig/autoLogoutWarningTimeout"];
            },
            autoLogoutWarningTimeoutText() {
                return this.$t("base.autoLogout.dialog.message", [
                    this.remainingTime,
                ]);
            },
            events: function () {
                return {
                    'onBarcodeRead': this.resetTimers
                };
            }
        },
        watch: {
            autoLogout(value) {
                if (value) {
                    this.addEventListeners();
                    this.resetTimers();
                } else {
                    this.removeEventListeners();
                    this.clearTimers();
                    AutoLogoutService.clear();
                }
            },
            autoLogoutTimeout() {
                this.resetTimers();
            },
            autoLogoutWarningTimeout() {
                this.resetTimers();
            },
            dialog(value) {
                if (value) {
                    this.removeEventListeners();
                    this.remainingTime = this.autoLogoutWarningTimeout;
                    this.countdownInterval = setInterval(() => {
                        if (this.remainingTime > 1) {
                            this.remainingTime--;
                        } else {
                            clearInterval(this.countdownInterval);
                            this.countdownInterval = null;
                        }
                    }, 60 * 1000);
                } else {
                    this.addEventListeners();
                    this.resetTimers();
                    if (this.countdownInterval) {
                        clearInterval(this.countdownInterval);
                        this.countdownInterval = null;
                    }
                }
            },
        },
        mounted() {
            this.addEventListeners();
            this.resetTimers();
        },
        beforeDestroy() {
            this.removeEventListeners();
            this.clearTimers();
        },
        methods: {
            addEventListeners() {
                if (this.autoLogout) {
                    this.domEvents.forEach((event) => {
                        window.addEventListener(event, this.resetTimers);
                    });
                }
            },
            removeEventListeners() {
                this.domEvents.forEach((event) => {
                    window.removeEventListener(event, this.resetTimers);
                });
            },
            setTimers() {
                if (this.autoLogout) {
                    this.logoutTimeout = setTimeout(() => {
                        AutoLogoutService.notification.set();
                        this.$store.dispatch("oauth/logout");
                    }, this.autoLogoutTimeout * 60 * 1000);
                    if ((this.autoLogoutTimeout - this.autoLogoutWarningTimeout) > 0) {
                        this.warningTimeout = setTimeout(() => {
                            if (this.autoLogoutWarning) {
                                this.dialog = true;
                            }
                        }, (this.autoLogoutTimeout - this.autoLogoutWarningTimeout) * 60 * 1000);
                    }
                    AutoLogoutService.set(Date.now() + (this.autoLogoutTimeout * 60 * 1000));
                }
            },
            clearTimers() {
                if (this.logoutTimeout) {
                    clearTimeout(this.logoutTimeout);
                    this.logoutTimeout = null;
                }
                if (this.warningTimeout) {
                    clearTimeout(this.warningTimeout);
                    this.warningTimeout = null;
                }
            },
            resetTimers() {
                this.clearTimers();
                this.setTimers();
            },
        },
    };
</script>

<style scoped lang="sass">
.auto-logout-title
  background-color: var(--v-warning-base)
  color: #fff !important

  > i
    color: #fff !important

</style>

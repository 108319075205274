<template>
  <div>
    <TaskBarcodeReader
      v-if="isInProgress && assignedToCurrentUser"
      :scanner-mode="scannerMode"
      :task-info="taskInfo"
      :ready="ready"
      @accept-barcode="handleAcceptBarcode"
      @reject-barcode="handleRejectBarcode"
      @clear-input="disengagePackingMode"
    />
    <template v-if="!packingMode">
      <v-layout
        class="ma-1 pb-1"
        wrap
      >
        <v-flex
          xs12
          md6
        >
          <PreparePackageOrderItems
            :items="items"
            :instance-details="instanceDetails"
            :toggle="true"
            :type="PreparePackageItemListTypes.TODO"
            @input="handleItemSelected"
          />
        </v-flex>
        <v-flex
          xs12
          md6
          :class="{
            'mt-2': $vuetify.breakpoint.smAndDown
          }"
        >
          <PreparePackageOrdersList
            :orders="orders"
            @open-order="openOrder"
          />
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <masonry
        :cols="$vuetify.breakpoint.mdAndUp ? 2 : 1"
        :gutter="0"
        class="ma-1 pb-1"
      >
        <PreparePackageOrderItems
          :items="activeOrder ? activeOrder.items : []"
          :loading="loadingOrderItems"
          :instance-details="instanceDetails"
          :type="PreparePackageItemListTypes.TODO"
          class="mb-2"
          @input="handleItemSelected"
        />
        <ShipmentDetail
          v-if="showShipmentCard"
          :shipment="activeOrder ? activeOrder.shipment : null"
          :order="activeOrder ? activeOrder.external_order : null"
          :order-items="activeOrder ? activeOrder.items : []"
          :shipment-completed-trigger="runShipmentCompleted"
          class="mb-2"
          @disengage-packing-mode="disengagePackingMode"
        />
        <PreparePackageOrderItems
          :items="activeOrder ? activeOrder.items : []"
          :loading="loadingOrderItems"
          :instance-details="instanceDetails"
          :type="PreparePackageItemListTypes.DONE"
          class="mb-2"
        />
        <OrderDetail
          :order="activeOrder ? activeOrder.external_order : null"
          :shipment="activeOrder ? activeOrder.shipment : null"
          :first-item-packed-trigger="runFirstItemPacked"
          class="mb-2"
        />
        <v-btn
          :block="$vuetify.breakpoint.smAndDown"
          color="secondary"
          outlined
          class="ml-1"
          @click="disengagePackingMode"
        >
          <v-icon
            class="mr-3"
          >
            $goBack
          </v-icon>
          {{ $t('tasks.preparePackage.disengagePackingMode') }}
        </v-btn>
      </masonry>
    </template>
    <v-dialog
      :value="dialogItem !== null"
      width="500"
      :overlay-opacity="0.9"
      @click:outside="cancelDialog"
    >
      <v-card v-if="dialogItem !== null">
        <v-card-title>
          {{ $t('tasks.preparePackage.haveYouPackedAllPieces') }}
        </v-card-title>
        <v-card-text>
          <span class="text-h3">{{ dialogItem.quantity_ordered - dialogItem.quantity_packed }}</span>&nbsp;
          <span>{{ instanceDetails[dialogItem.product_instance_id].product | productMeasureLabel }}</span>&nbsp;
          <span class="text-h5">{{ instanceDetails[dialogItem.product_instance_id] | instanceLabel }}</span>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap">
          <div class="d-flex flex-column align-start">
            <v-btn
              :loading="dialogLoading"
              outlined
              text
              @click="cancelDialog"
            >
              {{ $t('base.cancel') }}
            </v-btn>
          </div>
          <v-spacer />
          <div class="d-flex flex-column align-end">
            <v-btn
              color="secondary"
              :loading="dialogLoading"
              outlined
              text
              @click="confirmDialog"
            >
              {{ $t('tasks.preparePackage.packAllPieces', [dialogItem.quantity_ordered - dialogItem.quantity_packed]) }}
              {{ instanceDetails[dialogItem.product_instance_id].product | productMeasureLabel }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import {TaskPreparePackageAPI as API} from "@/api/TaskPreparePackageAPI";
    import {ACLMixin} from "@/app/mixins/ACLMixin";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import {TaskAssignMixin} from "@/app/mixins/TaskAssignMixin";
    import {TaskFetchItemsMixin} from "@/app/mixins/TaskFetchItemsMixin";
    import {TaskStateMixin} from "@/app/mixins/TaskStateMixin";
    import TaskBarcodeReader from "@/app/tasks/components/TaskBarcodeReader.component";
    import OrderDetail from "@/app/components/details/OrderDetail.component";
    import PreparePackageOrderItems from "@/app/tasks/preparePackage/components/PreparePackageOrderItems.component";
    import PreparePackageOrdersList from "@/app/tasks/preparePackage/components/PreparePackageOrdersList.component";
    import ShipmentDetail from "@/app/components/details/ShipmentDetail.component";
    import {CodeType} from "@/enum/code_type";
    import {PreparePackageItemListTypes} from "@/enum/prepare_package_item_list_type";
    import {scannerModes} from "@/enum/scanner_mode";
    import {PreparePackageMode} from "@/enum/prepare_package_mode";
    import {taskLinks, taskTypes} from "@/enum/task_type";
    import {readerFeedback} from "@/utils/readerFeedback";

    export default {
        name: "PreparePackagePrepare",
        components: {
            ShipmentDetail,
            PreparePackageOrdersList, PreparePackageOrderItems, OrderDetail, TaskBarcodeReader
        },
        mixins: [TaskStateMixin, TaskAssignMixin, ACLMixin, TaskFetchItemsMixin, RouteParamsMapperMixin],
        props: {
            taskInfo: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            ready: false,
            items: [],
            orders: [],
            loadingOrderItems: false,
            correctLocationChosen: false,
            runShipmentCompleted: 0,
            dialogItem: null,
            dialogLoading: false,
            API: API,
            PreparePackageItemListTypes: PreparePackageItemListTypes,
            runFirstItemPacked: 0
        }),
        computed: {
            orderId: {
                get: function () {
                    return +this.$route.query.orderId;
                },
                set: function () {
                    // set from RouteParamsMapperMixin do nothing
                }
            },
            activeOrder: function () {
                return this.orders.find(order => order.external_order.id === this.orderId);
            },
            packingMode: function () {
                return !isNaN(this.orderId);
            },
            scannerMode: function () {
                return this.correctLocationChosen
                    ? (this.packingMode ? scannerModes.ORDER : scannerModes.IDLE_ORDER_ITEM)
                    : scannerModes.IDLE_SCAN_PACKING;
            },
            mode: function () {
                return this.taskInfo.details.subordinate_stock.prepare_package_mode || PreparePackageMode.EAN_READ_EACH;
            },
            instanceDetails: function () {
                const ret = {};
                this.items.forEach(item => {
                    ret[item.product_instance_id] = item.instance;
                });
                return ret;
            },
            activeOrderAllPacked: function () {
                return (this.activeOrder ? this.activeOrder.items : [])
                    .every(item => item.quantity_packed >= item.quantity_ordered);
            },
            activeOrderNonePacked: function () {
                return (this.activeOrder ? this.activeOrder.items : [])
                    .every(item => item.quantity_packed === 0);
            },
            showShipmentCard: function () {
                // User needs to be packer AND either chief or storekeeper, or have everything packed to be able to manage the shipment
                return (this.isStorekeeper || this.isChief) || this.activeOrderAllPacked;
            }
        },
        watch: {
            packingMode: function (newValue) {
                if (!this._inactive) { // Do not execute for component which is being unloaded
                    if (newValue === false) {
                        this.fetchItems().catch(this.snack);
                    } else if (this.verifyOrderId()) {
                        this.fetchOrderItems();
                    }
                }
            },
            dialogItem: function (newValue) {
                if (!newValue) {
                    this.dialogLoading = false;
                }
            }
        },
        createdOrActivated: function (lifeCycleHook) {
            if (lifeCycleHook === this.LifeCycleHook.CREATED) {
                Promise.all([
                    this.fetchItems({initial: true}).catch(this.snack),
                    this.fetchOrders()
                ]).then(() => {
                    this.ready = true;
                });
            } else {
                this.fetchItems().catch(this.snack);
                this.fetchOrders();
            }
            if (this.mode !== PreparePackageMode.EAN_READ_EACH) {
                this.correctLocationChosen = true;
            }
        },
        methods: {
            fetchOrders: function () {
                return API.getAllOrders(this.taskId)
                    .then(response => {
                        this.orders = response.data;
                        if (this.packingMode) {
                            this.verifyOrderId();
                        }
                    }).catch(this.snack);
            },
            fetchOrderItems: function () {
                this.loadingOrderItems = true;
                const order = this.activeOrder;
                return API.getOrderItems(this.taskId, this.orderId)
                    .then(response => {
                        order.items = response.data;
                    })
                    .catch(this.snack)
                    .finally(() => {
                        this.loadingOrderItems = false;
                    });
            },
            verifyOrderId: function () {
                if (!this.orders.some(order => order.external_order.id === this.orderId)) {
                    this.snack('tasks.preparePackage.orders.unknown');
                    this.disengagePackingMode();
                    return false;
                }
                return true;
            },
            openOrder: function (orderId) {
                this.$router.push({
                    path: `${taskLinks[taskTypes.PREPARE_PACKAGE]}/${this.taskId}`,
                    query: {orderId: orderId}
                });
            },
            disengagePackingMode: function () {
                this.$router.push(`${taskLinks[taskTypes.PREPARE_PACKAGE]}/${this.taskId}`);
            },
            handleAcceptBarcode: function (barcodeInfo, quantity) {
                if (!this.correctLocationChosen) {
                    if (barcodeInfo.object_id === this.taskInfo.details.stock_location.id) {
                        this.correctLocationChosen = true;
                        this.readingDone();
                    } else {
                        this.readingFail('tasks.itemsCard.scanPackingLocation');
                    }
                    return;
                }
                if (barcodeInfo.type === CodeType.STOCK_LOCATION) {
                    this.readingFail('tasks.preparePackage.scanAnything');
                } else {
                    const toBePacked = barcodeInfo.quantity * quantity;
                    const instanceId = barcodeInfo.object_id;
                    if (this.packingMode && this.verifyOrderId()) {
                        const item = this.activeOrder.items.find(item => item.product_instance_id === instanceId);
                        if (item !== undefined) {
                            if (item.quantity_packed + toBePacked <= item.quantity_ordered) {
                                if (this.mode === PreparePackageMode.EAN_READ_EACH) {
                                    API.markItemAsPacked(this.taskId, item.id, this.orderId, toBePacked)
                                        .then(this.afterMarkedItemAsPacked).catch(this.readingFail);
                                } else if (this.mode === PreparePackageMode.SELECT_EACH) {
                                    if (this.dialogItem === null && (item.quantity_packed + toBePacked < item.quantity_ordered)) {
                                        this.dialogItem = item;
                                    } else {
                                        this.dialogItem = null;
                                        API.markItemAsPacked(this.taskId, item.id, this.orderId, toBePacked)
                                            .then(this.afterMarkedItemAsPacked).catch(this.readingFail);
                                    }
                                } else if (this.mode === PreparePackageMode.SELECT_FIRST_AUTO_PACK_REST) {
                                    const promises = [];
                                    this.activeOrder.items
                                        .filter(item => item.quantity_packed < item.quantity_ordered)
                                        .forEach(item => {
                                            promises.push(API.markItemAsPacked(
                                                this.taskId,
                                                item.id,
                                                this.orderId,
                                                item.quantity_ordered - item.quantity_packed
                                            ));
                                        });
                                    Promise.all(promises)
                                        .then(() => {
                                            this.afterMarkedItemAsPacked();
                                            this.snack('tasks.preparePackage.auto_packed_rest');
                                        }).catch(this.readingFail);
                                }

                            } else {
                                this.readingFail('tasks.preparePackage.scannedTooMuchItem');
                            }
                        } else {
                            this.readingFail('tasks.preparePackage.scannedWrongItem');
                        }
                    } else {
                        const item = this.items.find(item => item.product_instance_id === instanceId);
                        if (item) {
                            const order = item.orders.find(order => order.quantity_packed + toBePacked <= order.quantity_ordered);
                            if (order !== undefined && this.orders.some(curOrder => curOrder.external_order.id === order.order_id)) {
                                this.openOrder(order.order_id);
                                this.handleAcceptBarcode(barcodeInfo, quantity);
                            } else {
                                this.readingFail('tasks.preparePackage.scannedUnknownOrTooMuch');
                            }
                        } else {
                            this.readingFail('tasks.preparePackage.scannedUnknown');
                        }
                    }
                }
            },
            afterMarkedItemAsPacked: function () {
                readerFeedback.success();
                if (this.activeOrderNonePacked) {
                    this.runFirstItemPacked++;
                }
                this.fetchOrderItems().then(() => {
                    if (this.activeOrderAllPacked) {
                        this.runShipmentCompleted++;
                    }
                }).finally(this.readingDoneNoVibrate);
            },
            handleRejectBarcode: function () {
                this.readingFail();
            },
            handleItemSelected: function (productInstanceId) {
                if (this.mode === PreparePackageMode.EAN_READ_EACH) {
                    this.snack('tasks.preparePackage.ean_read_required');
                } else {
                    this.handleAcceptBarcode({
                        object_id: productInstanceId,
                        type: CodeType.PRODUCT_INSTANCE,
                        quantity: 1
                    }, 1);
                }
                // TODO #17478 - handle multi-quantity like IKEA, implement support for SELECT_FIRST_AUTO_PACK_REST
            },
            cancelDialog: function () {
                this.dialogItem = null;
            },
            confirmDialog: function () {
                this.dialogLoading = true;
                this.handleAcceptBarcode({
                    object_id: this.dialogItem.product_instance_id,
                    type: CodeType.PRODUCT_INSTANCE,
                    quantity: 1
                }, this.dialogItem.quantity_ordered - this.dialogItem.quantity_packed);
            }
        }
    };
    // TODO markItemAsUnpacked
</script>

<style scoped>

</style>

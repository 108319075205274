import Axios from "axios";
import {Electron} from "@/service/Electron";

export function setLang(Vue) {

    const lang = Vue.$store.getters['userConfig/lang'];

    Vue.$vuetify.lang.current = lang;
    Vue.$i18n.locale = lang;
    Vue.$moment.locale(lang);
    Axios.defaults.headers.common['Accept-Language'] = lang;
    Electron.setLang(lang);
}

export function setTheme(Vue) {
    const theme = Vue.$store.getters['userConfig/theme'];
    if (theme === 'system') {
        Vue.$vuetify.theme.dark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    } else {
        Vue.$vuetify.theme.dark = theme === 'dark';
    }
}

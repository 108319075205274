import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar'),_c(VContainer,{attrs:{"fluid":""}},[_c(VLayout,{attrs:{"wrap":""}},[_c(VFlex,{attrs:{"lg8":_vm.detailsMode,"xs12":""}},[_c('ShipmentsList',{attrs:{"shipment-id":_vm.shipmentId}})],1),(_vm.detailsMode && !_vm.isSmall)?_c(VFlex,{attrs:{"lg4":""}},[_c('ShipmentDetail',{attrs:{"shipment":_vm.shipments[_vm.shipmentId],"closeable":""},on:{"close":function($event){_vm.detailsMode = false}}}),_c('OrderDetail',{staticClass:"mt-2",attrs:{"order":_vm.shipments[_vm.shipmentId] ? _vm.orders[_vm.shipments[_vm.shipmentId].external_order_id] : null,"shipment":_vm.shipments[_vm.shipmentId]}})],1):_vm._e(),(_vm.isSmall)?_c(VDialog,{attrs:{"width":"500","overlay-opacity":0.9},model:{value:(_vm.detailsMode),callback:function ($$v) {_vm.detailsMode=$$v},expression:"detailsMode"}},[_c(VCard,[_c('ShipmentDetail',{attrs:{"shipment":_vm.shipments[_vm.shipmentId]}}),_c('OrderDetail',{staticClass:"mt-2",attrs:{"order":_vm.shipments[_vm.shipmentId] ? _vm.orders[_vm.shipments[_vm.shipmentId].external_order_id] : null,"shipment":_vm.shipments[_vm.shipmentId]}})],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import {Sentry} from "@/service/Sentry";

const SnackbarMixin = {
    methods: {
        /**
         * @param message {string}
         */
        snack: function (message) {
            if (!message) {
                this.$store.commit('snackbar/setVisible', false);
            } else if (this.$te(message)) {
                this.$store.commit('snackbar/set', { text: message });
            } else {
                Sentry.captureWarning('Untranslated text would be displayed to the user: ' + message);
                window.console.warn(message);
            }
        },

        /**
         * @param options {{
         *     text: string - untranslated text
         *     translatedText: string - has precedence over text
         *     params: Array,
         *     timeout: Number,
         *     undo: Function,
         *     close: Function
         * }}
         */
        advancedSnack: function (options) {
            this.$store.commit('snackbar/set', options);
        }
    }
};

export {SnackbarMixin};

<template>
  <span>
    <template v-if="isProductLoaded">
      <v-list-item>
        <v-list-item-avatar>
          <v-img
            :src="imgUrl"
            :lazy-src="imagePlaceholder"
            :alt="$t('products.images.alt')"
            style="cursor:zoom-in;"
            @click.stop="imageDialog = true"
          />
          <v-dialog
            v-model="imageDialog"
            :overlay-opacity="0.9"
          >
            <v-img
              :src="imgUrl"
              :lazy-src="imagePlaceholder"
              :alt="$t('products.images.alt')"
              contain
              max-height="90vh"
              max-width="90vw"
              @click.stop="imageDialog = false"
            />
          </v-dialog>
        </v-list-item-avatar>
        <v-list-item-content
          class="text-pre-wrap"
          style="cursor:pointer;"
          @click="$emit('select')"
        >
          <strong>{{ quantityLabel }}</strong> {{ itemInstance | instanceLabel }}
        </v-list-item-content>
      </v-list-item>
    </template>
    <template
      v-else
    >
      <v-progress-circular
        indeterminate
        size="14"
        width="2"
      />
    </template>
  </span>
</template>

<script>
    import {PreparePackageItemListTypes} from "@/enum/prepare_package_item_list_type";

    export default {
        name: "PreparePackageOrderItem",
        props: {
            item: {
                type: Object,
                default: () => ({})
            },
            itemInstance: {
                type: Object,
                default: () => ({})
            },
            type: {
                type: Number,
                default: PreparePackageItemListTypes.ALL
            }
        },
        data: () => ({
            imageDialog: false,
            imagePlaceholder: process.env.BASE_URL + 'assets/product_image_placeholder.jpg'
        }),
        computed: {
            isProductLoaded: function () {
                return this.itemInstance && this.itemInstance.product !== undefined;
            },
            quantityLabel: function () {
                switch (this.type) {
                case PreparePackageItemListTypes.TODO:
                    return (this.item.quantity_ordered - this.item.quantity_packed)
                        + this.$options.filters.productMeasureLabel(this.itemInstance.product);
                case PreparePackageItemListTypes.DONE:
                    return this.item.quantity_packed + '/' + this.item.quantity_ordered;
                case PreparePackageItemListTypes.ALL:
                    return this.item.quantity_ordered
                        + this.$options.filters.productMeasureLabel(this.itemInstance.product);
                default:
                    return '';
                }
            },
            imgUrl: function () {
                return this.itemInstance.product.default_image
                    || (
                        this.itemInstance.product.images.find(image => image.is_main_photo)
                        || this.itemInstance.product.images[0]
                        || [{url: this.imagePlaceholder}]
                    ).url;
            }
        }
    };
</script>

<style lang="sass">
.v-dialog
    box-shadow: none !important
</style>

<template>
  <div>
    <v-dialog
      v-if="bigImageIndex !== null"
      v-model="imageDialog"
      width="700"
      style="box-shadow: none;"
      :overlay-opacity="0.9"
    >
      <v-img
        :src="images[bigImageIndex].url"
        max-height="75vh"
        contain
      />
      <v-row class="mx-0 my-2 justify-space-between">
        <v-btn @click="moveImage('left')">
          <v-icon>
            $moveItemLeft
          </v-icon>
        </v-btn>
        <v-tooltip
          v-if="images[bigImageIndex].type === 'external'"
          top
        >
          <template #activator="{ on }">
            <v-btn
              :loading="loading"
              @click="storeImage(images[bigImageIndex])"
              v-on="on"
            >
              <v-icon>
                $saveItem
              </v-icon>
            </v-btn>
          </template>
          {{ $t("base.images.store.title") }}
        </v-tooltip>
        <v-btn @click="moveImage('right')">
          <v-icon>
            $moveItemRight
          </v-icon>
        </v-btn>
      </v-row>
    </v-dialog>
    <ConfirmDeleteDialog
      v-if="deletedImage !== null"
      :show.sync="confirmDialog"
      @confirm-delete="reallyDeleteImage"
    >
      <v-img
        :src="deletedImage.url"
      />
    </ConfirmDeleteDialog>
    <v-expansion-panels
      :value="0"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          v-if="isProductImage"
          class="text-subtitle-1"
        >
          {{ $t('base.images.plural') }}
        </v-expansion-panel-header>
        <v-expansion-panel-header v-else-if="isFoilImage">
          {{ $t('carriers.images.nameFoil') }}
        </v-expansion-panel-header>
        <v-expansion-panel-header v-else>
          {{ $t('base.images.name') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container
            v-show="images.length > 0"
            fluid
          >
            <v-row>
              <v-col
                v-for="(image, index) of images"
                :key="image.id"
                xs6
                sm3
                md2
                d-flex
                pa-2
              >
                <v-card
                  flat
                  tile
                  class="d-flex"
                >
                  <v-badge
                    :value="true"
                    color="muted"
                    overlap
                    left
                  >
                    <template #badge>
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-icon v-on="on">
                            {{ image.type === 'external' ? '$imageLink' : '$saveItem' }}
                          </v-icon>
                        </template>
                        {{ $t("base.images.type." + image.type) | capitalize }}
                      </v-tooltip>
                    </template>
                    <v-badge
                      :value="true"
                      color="error"
                      overlap
                    >
                      <template #badge>
                        <v-icon
                          dark
                          @click="deleteImage(image)"
                        >
                          $deleteItem
                        </v-icon>
                      </template>
                      <v-layout
                        column
                        justify-center
                      >
                        <v-img
                          :src="image.url"
                          :lazy-src="lazySrc"
                          :alt="$t(langPath + 'images.alt')"
                          :height="125"
                          :width="180"
                          contain
                          @click="showImage(index)"
                        >
                          <template #placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                              />
                            </v-row>
                          </template>
                        </v-img>
                        <v-btn
                          v-if="isProductImage"
                          :text="!image.is_main_photo"
                          outlined
                          class="mt-4"
                          :x-small="!image.is_main_photo"
                          @click="updateMainPhoto(image.id)"
                        >
                          {{ $t('base.images.' + (image.is_main_photo ? 'main' : 'setMain')) }}
                        </v-btn>
                      </v-layout>
                    </v-badge>
                  </v-badge>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <TableAddItemButton
            :to="buttonLink"
            :label="buttonLabel"
            :icon="buttonIcon"
            :show-divider="false"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import {ShipmentCarrierAPI} from "@/api/ShipmentCarrierAPI";
    import TableAddItemButton from "@/app/components/table/TableAddItemButton.component";
    import {RouteParamsMapperMixin} from "@/app/mixins/RouteParamsMapperMixin";
    import ConfirmDeleteDialog from "@/app/components/ConfirmDeleteDialog.component";

    export default {
        name: "ImagesList",
        components: {TableAddItemButton, ConfirmDeleteDialog},
        mixins: [RouteParamsMapperMixin],
        props: {
            singleImage: {
                type: Object,
                default: null
            },
            isFoilImage: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            imageDialog: false,
            confirmDialog: false,
            bigImageIndex: null,
            deletedImage: null,
            images: [],
            itemId: null,
            api: ProductAPI,
            langPath: 'products.',
            loading: false
        }),
        computed: {
            isProductImage: function () {
                return this.productId !== undefined;
            },
            buttonLink: function () {
                const domain = this.productId ? '/products/' : '/carriers/';
                let imageType = this.isFoilImage ? '/foil-image/' : '/image/';
                if (this.productId) {
                    imageType = '/images/';
                }
                const action = (this.images.length === 0 || this.productId) ? 'create' : 'update';
                return domain + this.itemId + imageType + action;
            },
            buttonLabel: function () {
                return (this.images.length === 0 || this.productId) ? 'base.images.create.titleSimple' : 'base.images.update.titleSimple';
            },
            buttonIcon: function () {
                return (this.images.length === 0 || this.productId) ? '$addItem' : '$updateItem';
            },
            lazySrc: function () {
                const publicPath = process.env.BASE_URL;
                return this.productId ? publicPath + 'assets/product_image_placeholder.jpg' : publicPath + 'assets/carrier_image_placeholder.jpg';
            }
        },
        watch: {
            singleImage: function () {
                this.getImages();
            },
            imageDialog: function (value) {
                if (!value) {
                    this.bigImageIndex = null;
                }
            }
        },
        createdOrActivated: function () {
            this.itemId = this.productId;
            if (!this.productId) {
                this.langPath = 'carriers.';
                this.itemId = this.carrierId;
                this.api = ShipmentCarrierAPI;
            }
            this.getImages();
        },
        methods: {
            getImages: function () {
                // TODO do not reload all image data when only update after change
                if (this.singleImage !== null) {
                    this.images = [this.singleImage];
                } else if (!this.productId) {
                    this.images = [];
                } else {
                    this.api.getAllImages(this.productId)
                        .then(response => {
                            this.images = response.data;
                        }).catch(() => {
                            this.images = [];
                            this.snack(this.langPath + 'images.unableToLoad');
                        });
                }
            },
            updateMainPhoto: function (imageId) {
                ProductAPI.updateImage(this.productId, imageId, true)
                    .then(this.getImages)
                    .catch(this.snack);
            },
            showImage: function (imageIndex) {
                this.bigImageIndex = imageIndex;
                this.imageDialog = true;
            },
            deleteImage: function (image) {
                this.deletedImage = image;
                this.confirmDialog = true;
            },
            reallyDeleteImage: function () {
                this.confirmDialog = false;
                const promise = this.isFoilImage ? this.api.deleteFoilImage(this.itemId) :
                    this.api.deleteImage(this.itemId, this.deletedImage.id);
                promise
                    .then(() => {
                        this.snack('base.images.delete.done');
                        this.$emit('imageDeleted');
                        this.getImages();
                    }).catch(this.snack);
            },
            moveImage: function (direction) {
                if (direction === 'left') {
                    this.bigImageIndex = (this.bigImageIndex - 1 + this.images.length) % this.images.length;
                }
                if (direction === 'right') {
                    this.bigImageIndex = (this.bigImageIndex + 1) % this.images.length;
                }
            },
            storeImage: function (image) {
                this.loading = true;
                const promise = this.isFoilImage ? this.api.storeExternalFoilImage(this.itemId) :
                    this.api.storeExternalImage(this.itemId, image.id);
                promise
                    .then(() => {
                        this.snack('base.images.store.done');
                        this.$emit('imageStored');
                        this.getImages();
                    }).catch(this.snack)
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    };
    // TODO resolve image sizes better
    // TODO move images using arrows
</script>

<style lang="sass">
.v-dialog
  box-shadow: none
</style>

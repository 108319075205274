import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('x-toolbar'),_c(VContainer,{attrs:{"fluid":""}},[_c('StockFilter',{attrs:{"form":_vm.form},on:{"update:form":function($event){_vm.form=$event}}}),_c('x-data-table',{attrs:{"headers":_vm.headers,"hidden-query-filters":_vm.hiddenFilters,"loading":_vm.loading,"items":_vm.items,"actions":_vm.actions,"api-data-source":_vm.apiDataSource,"api-data-source-all-pages":_vm.apiDataSourceAllPages,"reload":_vm.reload,"api-filter":_vm.apiFilter,"show-search-bar":false},on:{"update:loading":function($event){_vm.loading=$event},"update:items":function($event){_vm.items=$event},"update:filter":_vm.onTableFilterChange},scopedSlots:_vm._u([{key:"item.last_change",fn:function({ item }){return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.last_change}})]}},{key:"item.loaded_at",fn:function({ item }){return [_c('DateTimeWithTooltip',{attrs:{"date-time":item.loaded_at,"abs-date-first":""}})]}}])})],1),(_vm.isChief)?[_c(VDivider,{staticClass:"my-3"}),_c(VContainer,{attrs:{"fluid":""}},[_c('Alert',{attrs:{"display-text":_vm.$t('stocks.stockStatus.buyPrices.newLocation'),"show-alert":"","actions":[{
          text: _vm.$t('base.reports.redirect'),
          to: '/exports'
        }]}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
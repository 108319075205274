<template>
  <ToolbarSwitcher
    :label="switcher.label"
    :value-key="switcher.key"
  > 
    <template #append>
      <v-dialog
        v-model="dialog"
        width="600"
        :overlay-opacity="0.9"
      >
        <template #activator="{ on: onDialog, attrs: attrs }">
          <v-tooltip top>
            <template #activator="{ on: onTooltip }">
              <v-btn
                ref="activator"
                icon
                v-bind="attrs"
                class="ml-1 mb-1"
                v-on="{ ...onTooltip, ...onDialog }"
              >
                <v-icon class="setting-icon">
                  settings
                </v-icon>
              </v-btn>
            </template>
            {{ $t('base.autoLogout.setting.title') }}
          </v-tooltip>
        </template>
        <v-card elevation="8">
          <v-card-title class="d-flex flex-row flex-nowrap justify-space-between align-start auto-logout-title">
            <span style="word-break: normal;">
              {{ $t('base.autoLogout.setting.title') }}
            </span>
            <v-btn
              icon
              class="close-button"
              @click="dialog = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text class="pb-0 pt-4">
            <v-container class="px-0 py-0">
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      v-model="localAutoLogoutTimeout"
                      :label="autoLogoutInputLabel"
                      :placeholder="$t('base.autoLogout.setting.placeholder')"
                      type="number"
                      outlined
                      dense
                      min="0"
                      :rules="[formRules.positiveInteger]"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="localAutoLogoutWarning"
                      class="pa-0 mb-4 mt-0"
                      :label="autoLogoutWarningCheckboxLabel"
                      hide-details
                    />
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="ml-8"
                >
                  <v-col cols="12">
                    <v-text-field
                      v-model="localAutoLogoutWarningTimeout"
                      :disabled="!localAutoLogoutWarning || localAutoLogoutTimeout === ''"
                      :label="$t('base.autoLogout.setting.warning.label')"
                      :placeholder="$t('base.autoLogout.setting.warning.placeholder')"
                      type="number"
                      outlined
                      dense
                      min="0"
                      :rules="autoLogoutWarningTimeoutRules"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="!valid"
              color="accent"
              elevation="0"
              @click="save()"
            >
              <v-icon class="mr-2">
                $saveItem
              </v-icon>
              {{ $t('base.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </ToolbarSwitcher>
</template>

<script>
    import ToolbarSwitcher from "@/app/components/toolbar/ToolbarSwitcher.vue";
    import formRules from "@/utils/formRules";

    export default {
        name: "ToolbarAutoLogout",
        components: {ToolbarSwitcher},
        data: () => ({
            dialog: false,
            localAutoLogoutTimeout: null,
            localAutoLogoutWarning: false,
            localAutoLogoutWarningTimeout: null,
            formRules: formRules,
            valid: false
        }),
        computed: {
            switcher() {
                return {
                    label: this.$t('base.autoLogout.setting.label', [this.autoLogoutTimeout]),
                    key: 'autoLogout'
                };
            },
            autoLogout() {
                return this.$store.getters['userConfig/autoLogout'];
            },
            autoLogoutWarning: {
                get() {
                    return this.$store.getters['userConfig/autoLogoutWarning'];
                },
                set(value) {
                    this.$store.dispatch('userConfig/set', {
                        key: 'autoLogoutWarning',
                        value
                    });
                }
            },
            autoLogoutTimeout: {
                get() {
                    return this.$store.getters['userConfig/autoLogoutTimeout'];
                },
                set(value) {
                    this.$store.dispatch('userConfig/set', {
                        key: 'autoLogoutTimeout',
                        value
                    });
                }
            },
            autoLogoutWarningTimeout: {
                get() {
                    return this.$store.getters['userConfig/autoLogoutWarningTimeout'];
                },
                set(value) {
                    this.$store.dispatch('userConfig/set', {
                        key: 'autoLogoutWarningTimeout',
                        value
                    });
                }
            },
            autoLogoutInputLabel() {
                return this.$t('base.autoLogout.setting.label', [
                    this.localAutoLogoutTimeout === "" ? '-' : this.localAutoLogoutTimeout
                ]);
            },
            autoLogoutWarningCheckboxLabel() {
                return this.$t('base.autoLogout.setting.warning.checkbox', [
                    this.localAutoLogoutWarningTimeout === "" ? '-' : this.localAutoLogoutWarningTimeout
                ]);
            },
            autoLogoutWarningTimeoutRules() {
                return this.localAutoLogoutWarning 
                    && this.localAutoLogoutTimeout !== '' ? [
                        this.formRules.range(0, Number(this.localAutoLogoutTimeout))
                    ] : [];
            }
        },
        watch: {
            autoLogoutTimeout() {
                this.localAutoLogoutTimeout = this.autoLogoutTimeout;
            },
            localAutoLogoutTimeout() {
                if (this.$refs.form) {
                    this.$nextTick(() => {
                        this.$refs.form.validate();
                    });
                }
            },
            dialog() {
                if (this.dialog) {
                    this.localAutoLogoutWarning = this.autoLogoutWarning;
                    this.localAutoLogoutTimeout = this.autoLogoutTimeout;
                    this.localAutoLogoutWarningTimeout = this.autoLogoutWarningTimeout;
                }
            }
        },
        mounted() {
            this.localAutoLogoutWarning = this.autoLogoutWarning;
            this.localAutoLogoutTimeout = this.autoLogoutTimeout;
            this.localAutoLogoutWarningTimeout = this.autoLogoutWarningTimeout;
        },
        methods: {
            save() {
                this.autoLogoutWarning = this.localAutoLogoutWarning;
                this.autoLogoutTimeout = this.localAutoLogoutTimeout;
                if (this.localAutoLogoutTimeout) {
                    this.autoLogoutWarningTimeout = this.localAutoLogoutWarningTimeout;
                }
                this.dialog = false;
            }
        }
    };
</script>

<style scoped lang="sass">
.setting-icon:hover
  transform: rotate(-30deg)

.auto-logout-title
  background-color: var(--v-primary-base)
  color: #fff !important

.close-button > span > i
  color: #fff !important

</style>

<template>
  <v-dialog
    v-model="model"
    width="500"
    :overlay-opacity="0.9"
  >
    <v-card>
      <v-card-title>
        {{ $t('base.confirmDelete') }}
      </v-card-title>

      <v-card-text>
        <slot>
          {{ text }}
        </slot>
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          @click="model = false"
        >
          {{ $t('base.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="$emit('confirm-delete')"
        >
          {{ $t('base.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name: "ConfirmDeleteDialog",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
                default: null
            }
        },
        computed: {
            model: {
                get: function () {
                    return this.show;
                },
                set: function (newValue) {
                    this.$emit('update:show', newValue);
                }
            }
        }
    };
</script>

<style scoped>

</style>
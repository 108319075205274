<template>
  <div>
    <x-toolbar hide-domain />
    <v-container grid-list-xl>
      <div
        class="d-flex align-center justify-start flex-column mt-2"
      >
        <v-img
          alt="FulWMS logo"
          :src="logoSrc"
          max-width="80vw"
          class="mb-10"
        />
        <div
          v-if="lastUserExists"
          class="d-flex flex-column"
        >
          <v-btn
            ref="defaultButton"
            large
            color="secondary"
            :loading="loadingLogin"
            :disabled="loadingLogout || loadingRelogin"
            class="loginBtn my-4"
            @click="login()"
          >
            <v-icon
              x-large
              class="mr-2"
            >
              $continueAsSuggestedUser
            </v-icon>
            {{ $t('base.continueAs') }}
            <br>
            {{ lastUser }}
          </v-btn>
          <v-btn
            outlined
            color="secondary"
            :loading="loadingLogout"
            :disabled="loadingLogin || loadingRelogin"
            class="my-4"
            @click="logout()"
          >
            <v-icon
              class="mr-2"
            >
              $logout
            </v-icon>
            {{ $t('base.logout') }}
          </v-btn>

          <v-btn
            large
            outlined
            color="secondary"
            :loading="loadingRelogin"
            :disabled="loadingLogin || loadingLogout"
            class="my-4"
            @click="relogin()"
          >
            <v-icon
              large
              class="mr-2"
            >
              $loginAsSomebodyElse
            </v-icon>
            {{ $t('base.loginOther') }}
          </v-btn>
        </div>
        <div v-else>
          <v-btn
            ref="defaultButton"
            large
            color="secondary"
            class="loginBtn"
            :loading="loadingLogin"
            :disabled="loadingLogout || loadingRelogin"
            @click="login()"
          >
            <v-icon
              large
              class="mr-2"
            >
              $login
            </v-icon>
            {{ $t('base.login') }}
          </v-btn>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
    import {AutoLogoutService} from "@/service/AutoLogout";

    export default {
        name: "Login",
        data: () => ({
            lastUser: localStorage.getItem('lastUser'),
            loadingLogin: false,
            loadingLogout: false,
            loadingRelogin: false,
        }),
        computed: {
            lastUserExists: function () {
                return this.lastUser !== null;
            },
            logoSrc: function () {
                return process.env.BASE_URL + 'logo/fulwms-logo' + (this.$vuetify.theme.dark ? '-white' : '') + '-byjagu.svg';
            }
        },
        mounted: function () {
            this.$refs.defaultButton.$el.focus();
            if (AutoLogoutService.notification.check()) {
                this.advancedSnack({
                    text: 'base.autoLogout.notification.message',
                    timeout: 0,
                    close: () => {
                        AutoLogoutService.notification.clear();
                    }
                });
            }
        },
        methods: {
            login: function () {
                AutoLogoutService.notification.clear();
                this.loadingLogin = true;
                this.$store.dispatch('oauth/redirectToAuthUrl');
            },
            logout: function () {
                this.loadingLogout = true;
                this.$store.dispatch('oauth/logout');
            },
            relogin: function () {
                this.loadingRelogin = true;
                this.$store.dispatch('oauth/relogin');
            },
        }
    };
</script>

<style scoped lang="sass">
.loginBtn
    min-height: 75px
</style>

import {Store} from "@/service/store/Store";

const AUTO_LOGOUT_KEY = "autoLogout";
const AUTO_LOGGED_OUT_KEY = "autoLoggedOut";

export const AutoLogoutService = {
    check() {
        return new Promise((resolve, reject) => {
            let after = localStorage.getItem(AUTO_LOGOUT_KEY);
            if (after) {
                after = Number.parseInt(after, 10);
                if (Date.now() > after) {
                    this.notification.set();
                    return Store.dispatch("oauth/logout", true).then(reject);
                }
            }
            resolve();
        });
    },
    set(after) {
        localStorage.setItem(AUTO_LOGOUT_KEY, after);
    },
    clear() {
        localStorage.removeItem(AUTO_LOGOUT_KEY);
    },
    notification: {
        check() {
            const loggedOut = localStorage.getItem(AUTO_LOGGED_OUT_KEY);
            return loggedOut !== null && JSON.parse(loggedOut) === true;
        },
        set() {
            localStorage.setItem(AUTO_LOGGED_OUT_KEY, JSON.stringify(true));
        },
        clear() {
            localStorage.removeItem(AUTO_LOGGED_OUT_KEY);
        }
    }
};

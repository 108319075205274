import {Status} from "@/enum/import&export_state";
import {Domains} from "@/enum/domains";

const configNotFound = "Unable to find selected Template";
const exportNotFound = "Unable to find selected Import";
export default {
    title: "Export",
    exports: "Exports",
    link: "Export",
    status: "Status",
    domain: "Domain",
    download: "Download",
    errorMessage: "Error Message",
    processed_at: "Processed at",
    created_by: "Created by",
    created_at: "Created at",
    data_type: "Data type",
    type: "File type",
    export_create: "Create Export",
    error: "Error",
    continue: "Continue Export",
    view: "View Export",
    created: "Export Created",
    process: "Process Import",
    processed: "Export queued for processing",
    deleted: "Export Deleted",
    showStatistics: "Calculate export statistics",

    configuration: {
        title: "Export Template",
        templates: "Exports Templates",
        file: "Source File",
        create: "Create Export Template",
        created: "Export Template Created",
        config: "Template Configuration",
        config_subtitle: "If you wan to, you can configure your template here",
        updated: "Template Updated",
        create_copy: "Create Export Template Copy",
        view: "View Template",
        save: "Save Template",
        copy: "Copy Template",
        name: "Template Name",
        domain: "Data destination Domain",
        deleted: "Template Deleted",
        parameters: "Export Template parameters"

    },
    info: {
        title: "Export Information",
        hint: "Information about created export are being calculated, this action can take few minutes.",
        include: "Export will include: ",
        error: "Exporting with this configuration will lead to error with this message :",
        exported: "Rows to export",
        config_hint: "Here you can configure your template. You can toggle specific columns by clicking on their header. hidden columns will not be included in exports",
        parameters_hint: "Export parameters are used to filter exported data. Please fill all the shown parameters",
        statistics_warn: "Until the statistics are computed, the export cannot be completed, Do you still wish to continue?"
    },
    create: {
        title: "Create Export",
        dateHint: "Use YYYY-MM-DD for Dates"
    },
    parameters: {
        title: "Export Parameters",
        warning: "All Export Parameters must be Filled"
    },

    state: {
        [Status.CREATED]: "Created",
        [Status.UPLOADED]: 'Uploaded',
        [Status.QUEUED]: 'Queued',
        [Status.PROCESSING]: 'Processing',
        [Status.FAILED]: 'Failed',
        [Status.VALIDATION_FAILED]: 'Invalid template',
        [Status.FINISHED]: 'Finished',
        [Status.DRY_RUN_QUEUED]: 'Waiting for Statistics',
        [Status.DRY_RUN_PROCESSING]: 'Statistics are being Counted',
        [Status.DRY_RUN_FAILED]: 'Error while counting Statistics',
        [Status.DRY_RUN_VALIDATION_FAILED]: 'Invalid template for Statistics',
        [Status.DRY_RUN_FINISHED]: 'Statistics are Finished',
    },

    domains: {
        [Domains.BUYER]: 'Buyers',
        [Domains.PRODUCT]: 'Products',
        [Domains.SHIPMENT]: 'Shipments',
        [Domains.BUY_PRICE]: 'Buy price - stock level',
        [Domains.BUY_PRICE_FOR_STOCK_PICKING]: 'Buy price - picked',
        [Domains.BUY_PRICE_FOR_STOCK_LOADING]: 'Buy price - stocked',
        [Domains.BULK_EXTERNAL_ORDER]: 'External orders',
        [Domains.DAILY_LOCATION_OCCUPANCY_REPORT]: 'Daily occupancy',
        [Domains.DAILY_LOCATION_OCCUPANCY_BY_TYPE_REPORT]: 'Daily occupancy by type',
        [Domains.DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT]: 'Daily occupancy by type with movement',
        [Domains.INTERVAL_MOVEMENTS_REPORT]: 'Movements in interval',
        [Domains.SHARED_LOCATIONS_REPORT]: 'Shared locations',
        [Domains.PRODUCT_AT_STOCK]: 'Products at stock',
        [Domains.TASK_ATTRIBUTES]: 'Task attributes'
    },

    errors: {
        'export': {
            status: {
                404: exportNotFound,
            }
        },
        exportCreate: {
            status: {
                409: 'Unable to create Export'
            }
        },
        exportDelete: {
            status: {
                404: exportNotFound,
                409: "Unable to delete Export"
            }
        },
        exportUpdate: {
            status: {
                404: exportNotFound,
                409: 'Unable to update Export'
            }
        },
        exportProcess: {
            status: {
                404: exportNotFound,
                409: "Unable to process Export"
            }
        },
        config: {
            status: {
                404: configNotFound
            }
        },
        configCreate: {
            status: {
                404: configNotFound,
                409: "Unable to create Template"
            }
        },
        configUpdate: {
            status: {
                404: configNotFound,
                409: "Unable to update Template"
            }
        },
        configDelete: {
            status: {
                404: configNotFound,
                409: "Unable to delete Template"
            }
        },

    }
};

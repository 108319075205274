import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500","persistent":"","overlay-opacity":0.9},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('imports.info.warn'))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t(_vm.text))+" ")]),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('base.cancel'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"accent"},on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(" "+_vm._s(_vm.$t('tasks.locationTransfer.confirmButton'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
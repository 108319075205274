import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VMain,[_c('OfflineNotifier'),_c('ReadonlyNotifier'),(_vm.isAuthenticated)?_c('AutoLogout'):_vm._e(),_c(VContainer,{class:{
        'pa-1': _vm.$vuetify.breakpoint.smAndDown,
        'px-3': _vm.$vuetify.breakpoint.mdAndUp,
        'px-8': _vm.$vuetify.breakpoint.lgAndUp,
      },attrs:{"fluid":""}},[_c('keep-alive',{attrs:{"exclude":_vm.cacheExclude,"max":30}},[_c('router-view',{key:_vm.$route.path + '-' + (_vm.$route.meta.uuid || 0)})],1)],1)],1),_c('TimeTrackerNotice'),_c('LogoutConfirmDialog'),_c('Snackbar'),_c('LoaderOverlay'),_c('LoaderBottom')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-card-text>
      <v-chip-group
        column
      >
        <v-tooltip
          v-for="(location, index) in locationsSortedInput"
          :key="index"
          top
          :disabled="location.code === null"
        >
          <template #activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              class="ma-2"
              :color="getChipColor(location)"
              text-color="white"
              :model-value="true"
              v-on="on"
            >
              <div>{{ location.name }}</div>
              <v-icon
                size="small"
                class="mr-0 ml-1"
                @click="removeLocationFromInput(location)"
              >
                close
              </v-icon>
            </v-chip>
          </template>
          <span v-if="location.code !== null">{{ location.code }}</span>
        </v-tooltip>
      </v-chip-group>
    </v-card-text>

    <v-card-text>
      <v-form
        ref="newLocationNameForm"
        v-model="formValid"
        class="d-flex align-center"
        @submit.prevent
      >
        <FormFields
          :form="locationNameForm"
          :render="locationNameFormRender"
          lang-path="map."
        />
        <v-btn
          :text="!formValid"
          color="secondary"
          type="submit"
          class="mt-1 ml-2 mb-2"
          @click="addNewLocationToLocationGroup"
        >
          {{ $t('map.addLocation') }}
        </v-btn>
      </v-form>
    </v-card-text>
    <v-dialog
      v-model="locationCodeDialog"
      max-width="700px"
      :overlay-opacity="0.9"
    >
      <v-card
        class="pa-4"
      >
        <v-card-title
          class="mb-4"
        >
          {{ $t('map.locationCodeDecide.chooseLocation') }}
        </v-card-title>
        <v-card-text>
          <div
            class="mb-3"
          >
            {{ $t('map.locationCodeDecide.guide1') + locationCodeDialogData?.name + $t('map.locationCodeDecide.guide2') }}
          </div>
          <div
            v-if="locationCodeDialogData?.notMapped?.length > 0"
            class="mb-1"
          >
            {{ $t('map.locationCodeDecide.notMapped') }}:
          </div>
          <v-btn
            v-for="location in locationCodeDialogData?.notMapped"
            :key="location.stockLocationId"
            small
            depressed
            color="accent"
            class="mb-4 ml-4 mt-4"
            @click="addLocationFromDialog(locationCodeDialogData.name, location.stockLocationId, location.code)"
          >
            <v-icon
              left
            >
              $addItem
            </v-icon>
            {{ $t('map.locationCodeDecide.add1') }} {{ locationCodeDialogData.name }}  {{ $t('map.locationCodeDecide.add2') }} {{ location.code }}
          </v-btn>
          <div
            v-if="locationCodeDialogData?.mapped?.length > 0"
            class="mt-3 mb-1"
          >
            {{ $t('map.locationCodeDecide.mapped') }}:
          </div>
          <v-btn
            v-for="location in locationCodeDialogData?.mapped"
            :key="location.stockLocationId"
            small
            depressed
            color="muted"
            class="mb-4 ml-4 mt-4"
            @click="addLocationFromDialog(locationCodeDialogData.name, location.stockLocationId, location.code)"
          >
            <v-icon
              left
            >
              $addItem
            </v-icon>
            {{ $t('map.locationCodeDecide.add1') }} {{ locationCodeDialogData.name }}  {{ $t('map.locationCodeDecide.add2') }} {{ location.code }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
    import FormFields from "@/app/components/form/FormFields.component.vue";
    import {LocationNameForm, LocationNameRender} from "@/app/stocks/map/definition/objectDetails.form";
    export default {
        name: 'EditLocationsForm',
        components: {FormFields},
        props: {
            locations: {
                type: Array,
                default: () => [],
            },
            withoutCommit: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data: () => ({
            locationNameForm: new LocationNameForm(),
            locationNameFormRender: new LocationNameRender(),
            formValid: false,
            locationsInput: [],
            locationCodeDialog: false,
            locationCodeDialogData: null,
        }),
        computed: {
            locationsSortedInput() {
                const toSort = this.locationsInput;
                toSort.sort((location1, location2) => {
                    if (location1.name.toLowerCase() < location2.name.toLowerCase()) {
                        return -1;
                    }
                    if (location1.name.toLowerCase() > location2.name.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                });
                return toSort;
            }
        },
        watch: {
            locations: function (newValue) {
                this.locationsInput = newValue;
            }
        },
        created() {
            this.locationsInput = this.locations;
        },
        methods: {
            getChipColor: function (location) {
                const locationMappingAmount = this.$store.getters["storageMap/getLocationMappingForName"](location.name)?.locations?.find(loc => loc.stockLocationId === location.stockLocationId)?.mappedAmount;
                if (locationMappingAmount > (1 + (this.withoutCommit ? -1 : 0))) {
                    return 'locationDuplicit';
                }
                else if (location.stockLocationId === null) {
                    return 'locationToCreate';
                } 
                else {
                    return 'locationMapped'; 
                }
            },
            addNewLocationToLocationGroup: function () {
                
                if (this.formValid) {
                    const name = this.locationNameForm.locationName;
                    const locationMapping = this.$store.getters["storageMap/getLocationMappingForName"](name);
                    let stockLocationId = null;
                    let code = null;
                    if (locationMapping !== undefined && locationMapping.locations?.length === 1) {
                        stockLocationId = locationMapping.locations[0].stockLocationId;
                        code = locationMapping.locations[0].code;
                    }
                    if (locationMapping === undefined || locationMapping.locations === undefined || locationMapping.locations.length <= 1) {
                        this.$emit("addLocation", {
                            name: name,
                            stockLocationId: stockLocationId,
                            code: code
                        });
                    }
                    else {
                        this.locationCodeDialog = true;
                        this.locationCodeDialogData = {
                            name: locationMapping.name,
                            notMapped: locationMapping.locations.filter(loc => loc.mappedAmount < 1),
                            mapped: locationMapping.locations.filter(loc => loc.mappedAmount > 0)
                        };
                    }
                    this.$refs.newLocationNameForm.reset();
                }

            },
            addLocationFromDialog: function(name, stockLocationId, code) {
                this.$emit("addLocation", {
                    name: name,
                    stockLocationId: stockLocationId,
                    code: code
                });
                this.locationCodeDialog = false;
                this.locationCodeDialogData = [];
            },
            removeLocationFromInput: function (location) {
                this.$emit("removeLocation", {
                    location: location,
                });
            },
        }
    };
</script>

<style scoped>

</style>

import {Status} from "@/enum/import&export_state";
import {Domains} from "@/enum/domains";

const exportNotFound = 'Požadovaný export nebyl nalezen';
const configNotFound = "Požadovaná šablona nebyla nalezena";
export default {
    title: "Export",
    exports: "Exporty",
    link: "Export",
    status: "Stav",
    domain: "Oblast dat",
    download: "Stáhnout",
    errorMessage: "Chybová zpráva",
    processed_at: "Zpracováno",
    created_by: "Vytvořil(a)",
    created_at: "Vytvořeno",
    data_type: "Typ dat",
    type: "Typ souboru",
    export_create: "Vytvořit export",
    error: "Chyba",
    continue: "Pokračovat v Exportu",
    view: "Zobrazit Export",
    created: "Export vytvořen",
    process: "Zpracovat import",
    processed: "Export ve frontě na zpracování",
    deleted: "Export smazán",
    showStatistics: "Spočítat statistiky exportu",

    configuration: {
        title: "Šablona exportu",
        templates: "Šablony exportů",
        file: "Zdrojový soubor",
        create: "Vytvořit Šablonu exportu",
        created: "Šablona exportu vytvořeno",
        config: "Konfigurace Šablony",
        config_subtitle: "Pokud chcete, zde můžete konfigurovta svou šablonu.",
        updated: "Šablona Upravena",
        create_copy: "Vytvořit kopii Šablony exportu",
        view: "Zobrazit Šablonu",
        save: "Uložit Šablonu",
        copy: "Kopírovat Šablonu",
        name: "Název Šablony",
        domain: "Cílová oblast dat",
        deleted: "Šablona smazáno",
        parameters: "Parametry šablony exportu"

    },
    info: {
        title: "Informace o exportu",
        hint: "Informace o vytvořeném exportu se počítají, tato akce může několik minut trvat.",
        include: "Export bude zahrnovat: ",
        exported: "Řádků k exportu",
        error: "Při exportu s tímto nastavením dojde k chybě s tímto sdělením:",
        config_hint: "Zde nastavte vaši šablonu. Viditelnost sloupce se přepíná pomocí klikání na jeho hlavičku, schované sloupce nebudou zahrnuty v exportech",
        parameters_hint: "Parametry exportu dodávají možnost filtrovat exportovaná data. Prosím zadjte všechny parametry",
        statistics_warn: "Dokud se statistiky nespočítají, nebude možné export dokončit. Přejete si přesto pokračovat?"

    },
    create: {
        title: "Vytvořit export",
        dateHint: "Použijte formát RRRR-MM-DD pro datum"
    },
    parameters: {
        title: "Parametry exportu",
        warning: "Všechny parametry exportu musí být vyplněny"
    },

    state: {
        [Status.CREATED]: "Vytvořen",
        [Status.UPLOADED]: 'Nahrán',
        [Status.QUEUED]: 'Ve frontě',
        [Status.PROCESSING]: 'Zpracováván',
        [Status.FAILED]: 'Chyba',
        [Status.FINISHED]: 'Zpracován',
        [Status.VALIDATION_FAILED]: "Nevalidní šablona",
        [Status.DRY_RUN_QUEUED]: 'Čeká na statistiky',
        [Status.DRY_RUN_PROCESSING]: 'Statistiky se počítají',
        [Status.DRY_RUN_FAILED]: 'Chyba při počítání statistik',
        [Status.DRY_RUN_VALIDATION_FAILED]: 'Nevalidní šablona pro statistiky',
        [Status.DRY_RUN_FINISHED]: 'Statistiky spočteny',
    },

    domains: {
        [Domains.BUYER]: 'Odběratelé',
        [Domains.PRODUCT]: 'Skladové Položky',
        [Domains.SHIPMENT]: 'Zásilky',
        [Domains.BUY_PRICE]: 'Nákupní ceny - stav skladu',
        [Domains.BUY_PRICE_FOR_STOCK_PICKING]: 'Nákupní ceny - vyskladněno',
        [Domains.BUY_PRICE_FOR_STOCK_LOADING]: 'Nákupní ceny - naskladněno',
        [Domains.BULK_EXTERNAL_ORDER]: 'Externí Objednávky',
        [Domains.DAILY_LOCATION_OCCUPANCY_REPORT]: 'Denní obsazenost',
        [Domains.DAILY_LOCATION_OCCUPANCY_BY_TYPE_REPORT]: 'Denní obsazenost podle typu',
        [Domains.DAILY_LOCATION_OCCUPANCY_MOVEMENT_REPORT]: 'Denní obsazenost podle typu s pohyby',
        [Domains.INTERVAL_MOVEMENTS_REPORT]: 'Pohyby v intervalu',
        [Domains.SHARED_LOCATIONS_REPORT]: 'Sdílené umístění',
        [Domains.PRODUCT_AT_STOCK]: 'Zásoba produktů',
        [Domains.TASK_ATTRIBUTES]: 'Atributy úkolů'

    },

    errors: {
        'export': {
            status: {
                404: exportNotFound,
            }
        },
        exportCreate: {
            status: {
                409: 'Export se nepodařilo vytvořit'
            }
        },
        exportDelete: {
            status: {
                404: exportNotFound,
                409: "Export se nepodařilo smazat"
            }
        },
        exportUpdate: {
            status: {
                404: exportNotFound,
                409: 'Export se nepodařilo upravit'
            }
        },
        exportProcess: {
            status: {
                404: exportNotFound,
                409: "Export se napodailoodeslat ke zpracování."
            }
        },
        config: {
            status: {
                404: configNotFound
            }
        },
        configCreate: {
            status: {
                404: configNotFound,
                409: "Šablonu se nepodařilo vytvořit"
            }
        },
        configUpdate: {
            status: {
                404: configNotFound,
                409: "Šablonu se nepodařilo upravit"
            }
        },
        configDelete: {
            status: {
                404: configNotFound,
                409: "Šablonu se nepodařilo smazat"
            }
        },

    }

};

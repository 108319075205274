<template>
  <v-dialog
    v-model="showDialogCopy"
    width="500"
    :overlay-opacity="0.9"
  >
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>

      <v-card-actions class="d-flex flex-wrap">
        <div class="d-flex flex-column align-start">
          <v-btn
            outlined
            text
            @click="$emit('cancel')"
          >
            {{ $t('base.cancel') }}
          </v-btn>
          <div class="text--disabled caption">
            {{ $t('tasks.itemsCard.cancelByScanning') }}
          </div>
        </div>
        <v-spacer />
        <div class="d-flex flex-column align-end">
          <v-btn
            color="secondary"
            outlined
            text
            @click="$emit('confirm')"
          >
            {{ $t('tasks.itemsCard.scanConfirm', [locationCodeName]) }}
          </v-btn>
          <div class="text--disabled caption">
            {{ $t('tasks.itemsCard.confirmByScanning', [code]) }}
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
    import {BarcodeAPI} from "@/api/BarcodeAPI";
    import {CodeType} from "@/enum/code_type";
    import {locationLabel} from "@/utils/string";

    export default {
        name: "TaskBarcodeDialog",
        props: {
            showDialog: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: ''
            },
            code: {
                type: String,
                default: ''
            }
        },
        data: () => ({
            locationCodeName: '...'
        }),
        computed: {
            showDialogCopy: {
                get: function () {
                    return this.showDialog;
                },
                set: function (newValue) {
                    this.$emit('update:show-dialog', newValue);
                }
            }
        },
        watch: {
            code: {
                handler: function () {
                    if (this.code) {
                        BarcodeAPI.decode(this.code)
                            .then(response => {
                                if (response.data.type === CodeType.STOCK_LOCATION) {
                                    this.locationCodeName = locationLabel(response.data.object_info);
                                } else {
                                    this.locationCodeName = '...';
                                }
                            }).catch(this.snack);
                    } else {
                        this.locationCodeName = '...';
                    }
                },
                immediate: true
            }
        }
    };
</script>

<style scoped>

</style>

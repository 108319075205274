<template>
  <v-list-item class="d-flex align-center switcher">
    <v-switch
      v-model="value"
      :label="label"
      hide-details
    />
    <v-tooltip
      v-if="hint"
      top
    >
      <template #activator="{ on }">
        <v-icon
          class="ml-1 mb-1"
          v-on="on"
        >
          $hint
        </v-icon>
      </template>
      {{ hint }}
    </v-tooltip>
    <slot name="append" />
  </v-list-item>
</template>

<script>
    export default {
        name: "ToolbarSwitcher",
        props: {
            label: {
                type: String,
                default: null
            },
            valueKey: {
                type: String,
                default: ''
            },
            hint: {
                type: String,
                default: null
            }
        },
        computed: {
            value: {
                get() {
                    return this.$store.getters['userConfig/' + this.valueKey];
                },
                set(value) {
                    this.$store.dispatch('userConfig/set', {
                        key: this.valueKey,
                        value
                    });
                }
            }
        }
    };
</script>

<style scoped lang="sass">
.switcher > .v-input
  padding: 0
  margin: 0

</style>

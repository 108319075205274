import App from "@/App.vue";
import i18n from "@/service/lang/i18n";
import {checkTimeOnReload} from "@/service/router/RouterUtils";
import {CreatedOrActivatedPlugin} from "@/service/plugins/CreatedOrActivated.plugin";
import {Env} from "@/service/Environment";
import {EventBus} from "@/service/EventBus";
import {IndexedDB} from "@/service/cache/IndexedDB";
import loadExtendComponents from "@/service/Autoload";
import loadFilters from "@/utils/filters";
import {loadDynamicConfig} from "@/config/config";
import {registerAxiosInterceptor} from "@/utils/http";
import registerGlobalListeners from "@/utils/globalListeners";
import {Router} from "@/service/router/Router";
import {Sentry} from "@/service/Sentry";
import {setLang, setTheme} from "@/service/lang/utils";
import {SnackbarPlugin} from "@/service/plugins/Snackbar.plugin";
import {Store} from "@/service/store/Store";
import {useGoogleTagManager} from "@/service/GoogleTagManager";
import useMoment from "@/service/Moment";
import {useTipTap} from "@/service/TipTapEditor";
import Vue from "vue";
import {Vuetify} from "@/service/Vuetify";
import VueGridLayout from 'vue-grid-layout';
import VueMasonry from 'vue-masonry-css';
import {setFiltersi18n} from "@jagu/rest-api-filters-client/src/utils/i18n";
import VuetifyAsyncValidation from "vuetify-async-validation";
import {AutoLogoutService} from "@/service/AutoLogout";

loadDynamicConfig()
    .then(() => {
        Sentry.init();
        IndexedDB.clearOld(); // TODO move to a WebWorker
        useMoment();
        loadFilters();
        loadExtendComponents();
        registerAxiosInterceptor();
        registerGlobalListeners();
        useTipTap();
        Vue.use(VueMasonry);
        Vue.use(CreatedOrActivatedPlugin);
        Vue.use(SnackbarPlugin);
        Vue.use(VuetifyAsyncValidation);
        if (Env.isProduction()) {
            useGoogleTagManager();
        }

        // eslint-disable-next-line
        Vue.component('grid-layout', VueGridLayout.GridLayout);
        // eslint-disable-next-line
        Vue.component('grid-item', VueGridLayout.GridItem);

        setFiltersi18n(i18n);
        AutoLogoutService.check()
            .then(() => {
                Store.dispatch('oauth/tryToRestoreSession').finally(() => {
                    new Vue({
                        i18n,
                        router: Router,
                        store: Store,
                        vuetify: Vuetify,
                        beforeCreate: function () {
                            this.$store.commit('initialiseStore');
                            setLang(this);
                            setTheme(this);
                            checkTimeOnReload(this);
                        },
                        created: function () {
                            EventBus.$on('setDarkMode', (value) => {
                                this.$vuetify.theme.dark = value;
                            });
                            EventBus.$on('setLang', () => {
                                setLang(this);
                            });
                        },
                        render: h => h(App)
                    }).$mount('#app');
                });
            })
            .catch(() => {
                window.console.log('User has been auto-logged out.');
                AutoLogoutService.notification.set();
            });
    });

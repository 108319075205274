import {config} from "@/config/config";
import Vue from "vue";
import * as SentryService from "@sentry/vue";
import {Env} from "@/service/Environment";
import {Store} from "@/service/store/Store";

const Sentry = {

    enableInDev: false, // Just for quick testing

    init() {
        if (Env.isProduction() || this.enableInDev) {
            SentryService.init({
                app: Vue,
                dsn: "https://705cf6e7ac97413397ecee173ef00c1c@sentry.jagu.cz/11",
                environment: config.DOMAIN_LABEL,
                release: config.APP_VERSION,

                // Tracing
                integrations: [
                    SentryService.vueIntegration({
                        tracingOptions: {
                            trackComponents: true,
                        },
                    }),
                    SentryService.browserTracingIntegration(),
                    SentryService.replayIntegration({
                        maskAllText: false,
                        blockAllMedia: false,
                        networkDetailAllowUrls: [
                            /.*api.*/,
                        ],
                    }),
                ],
                tracesSampleRate: 0.1,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
            });
        }
    },

    registerUser() {
        SentryService.setUser({
            id: Store.getters['oauth/getUserId'],
            fullname: Store.getters['oauth/getUserFullName'],
        });
    },

    captureMessage(message, tags, additionalData) {
        if ((Env.isProduction() || this.enableInDev)) {
            SentryService.captureMessage(message, {
                context: 'info',
                tags: {
                    ...tags
                },
                extra: additionalData
            });
        }
    },

    captureWarning(warning) {
        if ((Env.isProduction() || this.enableInDev)) {
            SentryService.captureMessage(warning, 'warning',);
        }
    },

    captureException(error) {
        if ((Env.isProduction() || this.enableInDev)) {
            SentryService.captureException(error);
        }
    }
};

export {Sentry};

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"450","overlay-opacity":0.9},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('stocks.locations.setAvailability'))+" ")]),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCardText,[_c('FormFields',{ref:"fields",attrs:{"form":_vm.form,"render":_vm.formRender,"lang-path":"stocks.locations."}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":!_vm.valid,"type":"submit","color":"accent"},on:{"click":_vm.submit}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" $saveItem ")]),_vm._v(" "+_vm._s(_vm.$t('form.save'))+" ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
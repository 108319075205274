import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"600","overlay-opacity":0.9},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"elevation":"8"}},[_c(VCardTitle,{staticClass:"auto-logout-title"},[_c(VIcon,{staticClass:"mr-4"},[_vm._v(" $warning ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("base.autoLogout.dialog.title"))+" ")])],1),_c(VCardText,{staticClass:"pt-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.autoLogoutWarningTimeoutText)+" ")])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"accent","elevation":"0"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("base.autoLogout.dialog.continue"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
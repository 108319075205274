import {StockLocationLockType} from "@/enum/stock_location_lock_type";
import {PreparePackageMode} from "@/enum/prepare_package_mode";

const stockOrSubStockOrLocationNotFound = "Вибраний склад або підпорядкований склад або розміщення не існує.";

export default {
    title: "Склади",
    link: "Склади",

    name: "Назва складу",
    id: "Склади ID",
    description: "Опис",
    is_small: "Малий склад",
    address: "Адреса",

    chief: "Керівник складу",
    chief_id: "Керівник складу",

    locations: {
        label: "Розміщення на складі",
        name: "Назва розміщення",
        code: "Код",
        availability: "Доступність",
        availability_hint: "Вище число означає кращу доступність.",
        setAvailability: "Встановити доступність",
        availabilityBatch: {
            failed: "Не вдалося встановити доступність всіх місць!",
            done: "Доступність встановлена."
        },
        is_expedition: "Експедиційне",
        expedition: "Експедиційне",
        is_mobile: "Мобільне",
        mobile: "Мобільне",
        nonMobile: "Немобільне",
        codehint: "Залиште порожнім для автоматично згенерованого.",
        none: "<немає розташування>",
        at: "на розміщенні",
        is_allowed: "Дозволено для цього підпорядкованого складу",
        stockStatus: "Позиції на розміщенні",
        searchHint: "Пошук за назвою розміщення та кодом",

        printBarcode: "Надрукувати етикетку",
        printBarcodeMany: "Надрукувати етикетки",
        printBarcodeHowMuch: "Скільки етикеток '{0}' надрукувати?",
        printBarcodesHowMuch: "Скільки етикеток надрукувати?",
        printDone: "Друкується етикетка '{0}'...",

        items: {
            productsAtSubstock: "Товари на обраному місці",
            noItems: "На обраному місці немає нічого!"
        },

        locks: {
            strict_mode: "Строгий режим",
            strictHint: "Строгий замок дозволяє розміщувати продукти тільки на тих місцях, на які вони заблоковані.",
            note: "Примітка",
            lockTypes: {
                title: "Це місце обмежено на {0}",

                [StockLocationLockType.MANUFACTURER]: "Виробника",
                [StockLocationLockType.PRODUCT_TYPE]: "Тип товару",
                [StockLocationLockType.PRODUCT]: "Товар",
                [StockLocationLockType.INSTANCE]: "Конкретний екземпляр товару",
            },
            lockItems: {
                title: "Обмежити місце на",

                [StockLocationLockType.MANUFACTURER]: "Виробника",
                [StockLocationLockType.PRODUCT_TYPE]: "Тип товару",
                [StockLocationLockType.PRODUCT]: "Товар",
                [StockLocationLockType.INSTANCE]: "Конкретний екземпляр товару",
            },

            newType: "Тип нового замку",
            removeType: "Замок на {0}",

            removeLock: "Видалити замок",
            unableToRemoveLastLock: "Неможливо видалити останній замок!",
            lockAlreadySelected: "Цей замок вже обрано!",
            allTypesExist: "Це місце вже обмежено на всі можливі типи!",

            create: {
                done: "Замок на {0} створено.",
                titleSimple: "Додати замок"
            },
            update: {
                done: "Замок на {0} оновлено."
            },
            delete: {
                done: "Замок на {0} видалено."
            },

            status: {
                404: "Вказаний склад, місце або тип замку не існує!",
                409: "Неправильний тип замку!"
            }
        },

        show: {
            titleSimple: "Розташування",
            title: "Розташування '{0}' в '{1}'",
            label: "Позиції на місці"
        },
        create: {
            titleSimple: "Нове розташування",
            title: "Нове розташування в {0}",
            label: "Створити нове розташування",
            done: "Розташування '{0}' створено."
        },
        update: {
            titleSimple: "Оновити розташування",
            title: "Оновити розташування '{0}' в {1}",
            done: "Розташування '{0}' оновлено."
        },
        delete: {
            done: "Розташування '{0}' видалено.",
            failed: "Розташування '{0}' не можна видалити. Будь ласка, спробуйте знову пізніше.",
            status: {
                404: "Вказаний склад або розташування не існує!",
                409: "Розташування не може бути видалено через його зв'язки з іншими об'єктами!"
            }
        },

        status: {
            400: "Неправильний формат дати!",
            404: "Вказаний склад або розташування не існує!",
            409: "Вказаний код вже використовує інше розташування!"
        }
    },

    substocks: {
        label: "Підпорядковані склади",
        id: 'Підпорядкований склад ID',
        stock_id: 'Назва складу',
        name: "Назва підпорядкованого складу",
        description: "Опис",
        noSubstocks: "Немає підпорядкованого складу",
        owner: {
            name: "Власник"
        },
        owner_id: "Власник",
        has_permission: "має дозвіл",
        prepare_package_mode: "Режим упакування посилок",
        order_with_reservation: "Резервувати замовлені товари",
        auto_merge_stock_pickings: "Автоматично об'єднувати подібні розвантаження",
        auto_merge_stock_pickings_hint: "Не впливає на розвантаження, створені з зовнішніх замовлень.",
        allow_multiple_shipment_label_prints: "Дозволити кілька друків етикеток для замовлення",
        allow_multiple_shipment_label_prints_hint: "Керівник завжди може надрукувати кілька етикеток.",
        mark_as_delivered_after_packing: "Автоматична доставка особистої колекції після пакування",
        mark_as_delivered_after_packing_hint: "Відправлення замовлень типу \"особисте отримання\" будуть позначені як доставлені, як тільки вони будуть упаковані.",

        items: {
            productsAtSubstock: "Товари в обраному підпорядкованому складі",
            noItems: "У вибраному підпорядкованому складі немає нічого!"
        },

        show: {
            titleSimple: "Підпорядкований склад",
            title: "Підпорядкований склад '{0}' в '{1}'"
        },
        create: {
            titleSimple: "Новий підпорядкований склад",
            title: "Новий підпорядкований склад v {0}",
            label: "Створити новий підпорядкований склад",
            done: "Підпорядкований склад '{0}' створено.",
            addedPermission: "'{0}' тепер має доступ до '{1}'. Рекомендуємо встановити його для інших користувачів."
        },
        update: {
            titleSimple: "Оновити підпорядкований склад",
            title: "Оновити підпорядкований склад '{0}' в '{1}'",
            done: "Підпорядкований склад '{0}' оновлено."
        },
        delete: {
            done: "Підпорядкований склад '{0}' видалено.",
            failed: "Підпорядкований склад '{0}' не можна видалити. Будь ласка, спробуйте знову пізніше.",
            status: {
                404: "Вказаний склад або підпорядкований склад не існує!",
                409: "Підпорядкований склад не може бути видалено через його зв'язки з іншими об'єктами!"
            }
        },

        status: {
            404: "Вказаний склад або підпорядкований склад не існує!",
            409: "Підпорядкований склад не може бути видалено через його зв'язки з іншими об'єктами!"
        },

        allowedLocations: {
            status: {
                404: "Вказаний склад або підпорядкований склад не існує"
            }
        },

        allowedInstances: {
            status: {
                404: stockOrSubStockOrLocationNotFound
            }
        },

        whitelist: {
            list: "Список дозволених розміщень в '{0}'",
            available: "Розміщення, які можна дозволити для '{0}'",
            auto: "Автоматично дозволено",

            create: {
                title: "Додати до списку дозволених розміщень",
                done: "Розміщення '{0}' дозволено",
                failed: "Не вдалося дозволити '{0}'"
            },
            delete: {
                title: "Видалити зі списку дозволених розміщень",
                done: "Розміщення '{0}' заборонено",
                failed: "Не вдалося заборонити розміщення '{0}'",
                status: {
                    404: stockOrSubStockOrLocationNotFound,
                    409: "Місце не може бути видалено з підпорядкованого склдау, тому що на ньому розташовані товари!"
                }
            },
            batch: {
                true: "Дозволити відфільтровані",
                false: "Видалити дозволення відфільтрованих",
                checked: {
                    true: "Дозволити для підсховища",
                    false: "Видалити дозволення для підсховища",
                },
                done: {
                    true: "Розміщення дозволено",
                    false: "Дозвол скасовано",
                },
                failed: "Зміна дозволів не вдалася!"
            },
            status: {
                404: "Вказане сховище, підсховище або розміщення не існує!",
                409: "Обране місце вже дозволено в цьому підсховищі!"
            }
        },

        prepare_package_mode_items: {
            [PreparePackageMode.EAN_READ_EACH]: "Вимагати сканування кожного товару",
            [PreparePackageMode.SELECT_EACH]: "Дозволити вибір кожного товару вручну",
            [PreparePackageMode.SELECT_FIRST_AUTO_PACK_REST]: "Дозволити вибір першого товару вручну, потім автоматично пакувати все з цього замовлення"
        },

        task_settings: {
            label: "Налаштування завдань",
            autoApprove: "Автоматично затверджувати завершені завдання",
            autoPrintReport: "Автоматично друкувати звіти після завершення завдання",
            sortTaskItemsByLoaded: 'Сортуйте розташування в завданнях від найстаріших до новіших',
            updateDone: "Налаштування оновлено",
            status: {
                404: "Вказаний підпорядкований склда не існує!"
            }
        },

        task_attributes: {
            label: "Налаштуйте атрибути завдання",
            forSubstock: "Дозволені атрибути для кожного типу завдань у цьому піддереві",
            forStock: "Дозволені атрибути для кожного типу завдань на складі",
            forStockHint: "Для деяких типів завдань підзапас не вказується, тому атрибути встановлюються для всього запасу.",
            updateDone: "Налаштування оновлено",
        },

        order_processing: {
            label: "Автоматичне об'єднання розвантажень для замовлень",
            runNow: "Запустити створення об'єднаного розвантаження замовлень відразу",
            enable_automatic_triggering: "Запускати автоматично",
            max_automatic_sets: "Максимальна кількість одночасно існуючих груп",
            min_orders_per_automatic_set: "Мінімальне вилучення в 1 групі",
            max_orders_per_automatic_set: "Максимальне розвантаження в 1 групі",
            strategies: "Стратегії створення об'єднаного розвантаження",
            strategyPriority: "Пріоритет стратегій",
            strategy: {
                SAME_SINGLE_PRODUCT: "Одинаковий одиночний продукт",
                SINGLE_PRODUCT: "Один продукт",
                IDENTICAL_ORDERS: "Ті ж самі замовлення",
                FROM_OLDEST: "Від найстаріших"
            },
            updateDoneWithDisabling: "Конфігурацію оновлено, автоматичне об’єднання вимкнено",
            activationDone: "Автоматичне об’єднання активовано",
            deactivationDone: "Автоматичне об’єднання деактивовано",
            updateDone: "Налаштування оновлено",
            run: {
                status: {
                    204: "Задачу додано до черги для обробки",
                    404: "Вказаний склад або його підпорядкований склад не існують!",
                }
            },
            status: {
                404: "Вказаний склад або його субсклад не існують!",
                409: "Неправильна конфігурація обробки замовлень!"
            },
            active: "Автоматичне об’єднання було активовано",
            deactivated: "Автоматичне об’єднання було деактивовано",
            tooltipActivationButtonDeactivated: "Для активації має бути вибрано принаймні одну стратегію, і всі значення в наведеній нижче конфігурації мають бути додатними цілими числами. Все потрібно зберегти.",
            tooltipActivationButtonCanNotBeChanged: "Перед перемиканням необхідно зберегти зміни кнопкою нижче.",
        },

        buyer_types: {
            label: "Призначені типи покупців",
        }
    },

    owners: {
        name: "Назва",
        ico: "Ідентифікаційний код",
        dic: "ІПН",
        phone: "Телефон",
        email: "E-mail",
        website: "Веб-сайт",
        billing_address: "Юридична адреса",
        bank_account: "Номер рахунку",

        unableToLoad: "Власника не вдалося завантажити. Будь ласка, спробуйте ще раз.",

        link: "Власники підпорядкованих складів",

        list: {
            title: "Власники підпорядкованих складів"
        },
        show: {
            title: "Власник підпорядкованого складу"
        },
        create: {
            label: "Створити нового власника",
            titleSimple: "Новий власник підпорядкованого складу",
            title: "Новий власник підпорядкованого складу '{0}'",
            done: "Власника '{0}' створено."
        },
        update: {
            titleSimple: "Редагувати власника",
            title: "Редагувати власника '{0}'",
            done: "Власника '{0}' оновлено."
        },
        delete: {
            done: "Власника '{0}' видалено!",
            failed: "Не вдалося видалити власника '{0}'. Будь ласка, спробуйте ще раз пізніше.",
            status: {
                404: "Вказаний власник субскладу не існує!",
                409: "Власника підпорядкованого складу не можна видалити через його відношення з іншими об'єктами!"
            }
        },

        status: {
            404: "Вказаний власник складу не існує!",
            409: "Вказаний ІПН вже має інший власник складу!"
        }
    },

    stockChoose: {
        form: {
            stockId: "Виберіть склад",
            subStockId: "Виберіть підпорядкований склад",
            locationId: "Лише на місці розташування ...",
            productId: "Лише товар ...",
            instanceId: "Лише конкретниц екземпляр товару ...",
        }
    },

    stockStatus: {
        link: "Стан складу",
        title: "Огляд стану складу",
        allSubstocks: "<всі підпорядковані склади>",
        allLocations: "<всі місця розташування в складі>",
        allLocationsIn: "<всі місця розташування в '{0}'>",
        allProducts: "<всі предмети>",
        optional: "Додаткове уточнення",
        table: {
            stock: {
                id: "Склад",
                choose: "Виберіть склад"
            },
            product_instance: {
                id: "Назва предмета",
                choose: "Виберіть товар"
            },
            stock_location: {
                id: "Розташування"
            },
            quantity: "Кількість",
            last_change: "Остання зміна",
            loaded_at: "Найстаріший предмет"
        },
        showItem: "Показати предмет",
        showLocation: "Показати розташування",

        locations: {
            link: "Звіт про розташування",
            title: "Звіт про щоденне використання розташувань",
            generating: "Звіт готується...",
            form: {
                stockId: "Склад",
                subStockId: "Підпорядкований склад",
                dateFrom: "з",
                dateTo: "по",
            },
            table: {
                location_id: "ID розташування",
                location_name: "Назва розташування",
                days_used: "Дні використання"
            }
        },

        buyPrices: {
            name: "Експорт цін на закупівлю",
            title: {
                IN: "Експорт цін на закупівлю - запасено",
                CURR: "Експорт цін на закупівлю - стан складу",
                OUT: "Експорт цін на закупівлю - відвантажено"
            },
            exportTitle: "Закупівельні ціни - стан складу",
            newLocation: "Звіт про закупівельні ціни тепер можна знайти на вкладці 'Експортувати'.",

            stockId: "Склад",
            subStockId: "Підпорядкований склад",
            from_date: "з",
            to_date: "по",
            date_from: "з",
            date_to: "по",

            status: {
                409: "Неприпустима дата"
            }
        }
    },

    stockMovements: {
        link: "Переміщення на складі",
        title: "Огляд переміщень на складі",
        table: {
            type: "Тип переміщення",
            product_instance: {
                id: "Складський товар"
            },
            task: {
                id: "Завдання",
                type: "Тип завдання",
            },
            substock: {
                id: 'Підпорядкований склад'
            },
            location: {
                id: 'Місцезнаходження',
                type: 'Тип місцезнаходження',
                STOCK: 'Склад',
                USER: 'Інвентар комірника'
            },
            movement: {
                PUT: 'В',
                PICK: 'З'
            },
            amount: "Кількість",
            created_at: "Дата",
            created_by: "Переміщено користувачем",
        },
    },

    list: {
        unableToLoad: "Не вдалося завантажити список складів. Будь ласка, спробуйте пізніше.",
    },
    show: {
        titleSimple: "Деталі складу",
        title: "{0}"
    },
    create: {
        title: "Новий склад",
        done: "Склад '{0}' створено."
    },
    update: {
        titleSimple: "Оновити склад",
        title: "Оновити {0}",
        done: "Склад '{0}' оновлено."
    },
    delete: {
        done: "Склад '{0}' видалено.",
        failed: "Склад '{0}' не можна видалити. Будь ласка, спробуйте пізніше."
    },

    status: {
        400: "Невірний формат дати!",
        404: "Вказаний склад не існує!",
        409: "Склад не можна видалити через його зв'язки з іншими об'єктами!",
        423: "Звіт ще не готовий. Будь ласка, спробуйте повторити дію пізніше."
    }
};

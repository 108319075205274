import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500","overlay-opacity":0.9},model:{value:(_vm.showDialogCopy),callback:function ($$v) {_vm.showDialogCopy=$$v},expression:"showDialogCopy"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardActions,{staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"d-flex flex-column align-start"},[_c(VBtn,{attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t('base.cancel'))+" ")]),_c('div',{staticClass:"text--disabled caption"},[_vm._v(" "+_vm._s(_vm.$t('tasks.itemsCard.cancelByScanning'))+" ")])],1),_c(VSpacer),_c('div',{staticClass:"d-flex flex-column align-end"},[_c(VBtn,{attrs:{"color":"secondary","outlined":"","text":""},on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(" "+_vm._s(_vm.$t('tasks.itemsCard.scanConfirm', [_vm.locationCodeName]))+" ")]),_c('div',{staticClass:"text--disabled caption"},[_vm._v(" "+_vm._s(_vm.$t('tasks.itemsCard.confirmByScanning', [_vm.code]))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="py-5">
    <Alert
      :display-text="$t('stocks.stockStatus.buyPrices.newLocation')"
      show-alert
      :actions="[{
        text: $t('base.reports.redirect'),
        to: '/exports'
      }]"
    />
  </div>
</template>

<script>
    import Alert from "@/app/components/Alert.component.vue";

    export default {
        name: "StockBuyPricesBundleComponent",
        components: {Alert}
    };
</script>

<style scoped>

</style>

import {PrintType} from "@/enum/print_type";
import {ShipmentState} from "@/enum/shipment_state";
import {APIFilterOP} from "@/service/APIFilters";
import {AttributeDataTypes} from "@/enum/attribute_data_types";

export default {
    and: "та",
    or: "або",
    close: "Закрити",
    undo: "Відмінити",
    open: "Відкрити",
    used: "вживаний",
    free: "вільний",
    reset: "Скинути",
    about: "Про FulWMS",
    loading: "Завантаження",
    cancelLoading: "Скасувати та перейти до перегляду",
    cancelLoadingHint: "Завантаження в фоновому режимі може завершитись, але не мусить закінчитись!",
    home: "Додому",
    notFound: "Не знайдено",
    pageNotFound: "Сторінку не знайдено",
    darkMode: "Темний режим",
    productLabels: "Зображення назви продукту",
    productModel: {
        0: "Назва",
        1: "Модель"
    },
    showProductImagesInTasks: "Показувати фотографії продуктів у завданнях",
    pickFromMobileLocation: "Пропонувати збір товару з мобільного розташування",
    automagicallyChooseInstance: {
        label: "Автоматично вибирати конкретні товари",
        hint: "Для друку етикеток, якщо завантажується неправильний конкретний продукт."
    },
    onBeep: "При сигналі",
    search: "Пошук",
    searchAll: "Шукати скрізь",
    searchHint: "Пошук за {0} чи {1}.",
    searchHintSingle: "Пошук за {0}.",
    searchResult: {
        searchesIn: "Шукає завдання (номер, зовнішній ID, стан, пріоритет та тип), товари (виробник, назва та модель), склади (назва), особи (ІПН) та штрих-коди.",
        tasksById: "Завдання",
        tasksByExternalId: "Завдання з зовнішнім ID {0}",
        tasksByState: "Завдання зі станом {0}",
        tasksByPriority: "Завдання з пріоритетом {0}",
        tasksByType: "Завдання типу {0}",
        productsByBarcode: "Штрих-код товару",
        productsByManufacturer: "Товари виробника {0}",
        productsByName: "Товари з '{0}' в назві",
        productsByModel: "Товари з моделлю {0}",
        stocksByName: "Склади з '{0}' в назві",
        locationsByBarcode: "Штрих-код місцезнаходження",
        buyersByIco: "Покупець",
        suppliersByIco: "Постачальник",
        stockOwnersByIco: "Власник підзапасу"
    },
    advancedFilter: "Розширені фільтри",
    filterBy: "Фільтрувати",
    useHeaderToSearch: "Для пошуку використовуйте поле в заголовку таблиці",
    orWriteYourOwn: "або почніть писати свій",
    noIco: "ІПН не заповнений",
    actions: "Дії",
    nothing: "Нічого",
    no: "Ні",
    yes: "Так",
    of: 'з',
    pcs: 'шт',
    view: "Показати",
    hide: "Приховати",
    showAll: "Показати всі",
    hideAll: "Приховати всі",
    edit: "Редагувати",
    delete: "Видалити",
    confirmDelete: "Дійсно видалити?",
    reload: "Перезавантажити",
    save: "Зберегти",
    saved: "Збережено",
    notSave: "Не зберігати",
    cancel: "Скасувати",
    active: 'Активний',
    clickToCopy: "Копіюйте клацанням",
    copyToClipboard: "Скопіювати в буфер обміну",
    copyToClipboardDone: "'{0}' скопійовано!",
    copyToClipboardFail: "Помилка копіювання!",
    goBack: "Повернутися на попередню сторінку",
    goUp: "Повернутися на рівень вище",
    lang: "Мова",
    help: {
        title: "Допомога"
    },
    sort: {
        DESC: 'за спаданням',
        ASC: 'за зростанням'
    },
    cache: {
        title: "Управління кешем",
        contents: "Вміст кешу",
        length: "Кількість",
        size: "Розмір",
        clear: "Очистити",
        clearAll: "Очистити все",
        taskDetails: "Деталі завдання",
        barcodes: "Штрих-код",
        buyers: "Покупець",
        locations: "Місцезнаходження",
        allowedLocationIds: "Дозволені місця товару",
        instances: "Деталі товару",
        products: "Товар",
        instanceBarcodes: "Штрих-коди товару",
        instanceTypes: "Спеціальні випадки",
        users: "Користувач"
    },
    login: "Увійти",
    continueAs: "Продовжити як",
    loginOther: "Увійти як інший користувач",
    logout: "Вийти",
    autoLogout: {
        setting: {
            title: "Налаштування автоматичного виходу з системи під час простою",
            label: "Автоматичний вихід через {0} хвилин",
            placeholder: "наприклад, 60",
            warning: {
                checkbox: "Показувати попередження за {0} хвилин до автоматичного виходу",
                label: "Введіть кількість хвилин",
                placeholder: "наприклад, 3"
            }
        },
        dialog: {
            title: "Автоматичний вихід з системи",
            message: "Через {0} хвилин ви автоматично вийдете з системи через неактивність.",
            continue: "Продовжуйте працювати",
        },
        notification: {
            message: "Ви вийшли з системи. Будь ласка, увійдіть ще раз."
        }
    },
    confirmLogout: "Ви впевнені, що хочете вийти з системи?",
    logoutHint: "Ви вийдете з усіх відкритих вкладок.",
    version: "Версія",
    offline: "Без з'єднання з сервером!",
    readonly: "Сервіс є в режимі тільки для читання! Ви неможете виконувати жодні операції.",
    download: "Завантажити {0}",
    uploadAttachment: "Завантажити вкладення",
    filter: {
        [APIFilterOP.AND]: 'і одночасно',
        [APIFilterOP.OR]: 'або',
        [APIFilterOP.IS_NULL]: '{0} порожнє',
        [APIFilterOP.IS_NOT_NULL]: '{0} не порожнє',
        [APIFilterOP.ARRAY_CONTAINS]: 'список {0} містить {1}',
        [APIFilterOP.ARRAY_NOT_CONTAINS]: 'список {0} не містить {1}',
        [APIFilterOP.ARRAY_EMPTY]: 'список {0} порожній',
        [APIFilterOP.ARRAY_NOT_EMPTY]: 'список {0} не порожній',
        [APIFilterOP.LIKE]: '{0} містить {1}',
        [APIFilterOP.NOT_LIKE]: '{0} не містить {1}',
        [APIFilterOP.EQUALS]: '{0} дорівнює {1}',
        [APIFilterOP.NOT_EQUALS]: '{0} не дорівнює {1}',
        [APIFilterOP.GREATER_THAN]: '{0} більше ніж {1}',
        [APIFilterOP.LOWER_THAN]: '{0} менше ніж {1}',
        [APIFilterOP.GREATER_THAN_OR_EQUAL]: '{0} більше або дорівнює {1}',
        [APIFilterOP.LOWER_THAN_OR_EQUAL]: '{0} менше або дорівнює {1}',
        [APIFilterOP.BETWEEN]: '{0} між {1} і {2}',
        [APIFilterOP.IN]: '{0} є одним з: {1}',
        [APIFilterOP.NOT_IN]: '{0} не є жодним з: {1}',
    },
    filterConfig: {
        filter: "Фільтр",
        invalid: "Фільтр не є дійсним!",
        sort: "Сортування",
        condition: "Умова",
        addCondition: "Додати умову",
        splitCondition: "Розділити умову",
        removeCondition: "Видалити умову",
        nestedGroup: "Вкладені умови",
        nonNested: "Прямі умови",
        addFilter: "Додати фільтр",
        addSort: "Додати сортування",
        removeSort: "Видалити сортування",
        sortThen: "потім за",
        fill: "Заповніть",
        fillFrom: "Заповнити з",
        fillTo: "Заповнити до",
        attributeChanged: "Атрибут змінено з {0} на {1}.",
        booleanValues: {
            true: "Правда",
            false: "Неправда"
        },
        select: "Вибрати",
        fromInputError: "Значення повинно бути менше або дорівнювати {0}",
        toInputError: "Значення повинно бути більше або дорівнювати {0}",
    },
    regex: {
        '^': 'Початок',
        '$': 'Кінець',
        dot: 'Будь-який символ',
        '?': 'Немає або одне входження',
        '*': 'Немає або будь-яка кількість входжень',
        '+': 'Одне або більше входжень',
        '\\': 'Наступний символ не є спеціальним',
        '()': 'Розмежування груп символів',
        squareBrackets: "Пошук певного символу у групі",
        '{}': 'Вказівка певної кількості повторів',
        },
    table: {
        batchActions: "Масові дії",
        removeSelection: "Видалити вибір",
        batchActionDisabledByFulltext: "Не можна комбінувати з повнотекстовим пошуком. Використовуйте розширені фільтри.",
        batchAllFiltered: "Для всіх відфільтрованих",
        batchAllSelected: "Для всіх відзначених",
        filter: {
            chooseAttr: '' // навмисно порожній
        }
    },
    reports: {
        title: "Звіти",
        domain: "Зона звітності",
        type: "тип звіту",
        new_location: "Цей звіт тепер можна знайти на вкладці 'Звіти'.",
        redirect: "Перенаправлення на"
    },
    shipping: {
        link: 'Відправлення',
        title: 'Відправлення',
        optional: "Необов'язкові фільтри",
        table: {
            state: "Стан",
            external_order_external_order_id: 'Екст. № зам.',
            external_order_id: "Замовлення",
            stock_picking_id: "Комплектація",
            buyer: {
                id: "Покупець",
                name: "Покупець",
                email: "E-mail",
                phone: "Телефон"
            },
            subordinate_stock_id: "Підсклад",
            carrier_id: "Перевізник",
            last_validated: "Перевірена",
            carrier_api_error_messages: "Помилки перевізника",
            external_order_created_at: 'Замовлення створено',
            created_at: 'Відправлення створено',
            label_first_printed_at: "Етикетка вперше надрукована",
            last_protocol_created_at: "Останній протокол створено",
            sent_date: "Надіслано",
            total_value: "Ціна",
            total_weight: "Вага",
            package_count: "Кількість пакунків",
            searchHint: "Пошук за перевізником, покупцем, імейлом, телефоном чи екстерному номеру замовлення"
        },
        shipment: {
            show: "Показати посилку",
            printLabel: "Друк етикетки посилки",
            printDetail: {
                label: "Роздрукуйте звіт про передачу",
                hint: "Спочатку вифільтруйте тільки один підсклад та тільки одного перевізника."
            },
            track: "Стежити за посилкою",
            state: {
                [ShipmentState.CREATED]: "Нова",
                [ShipmentState.WAITING_FOR_PICKUP]: "Очікує на відправлення",
                [ShipmentState.SENT]: "Відправлена",
                [ShipmentState.BEING_DELIVERED]: "Доставляється",
                [ShipmentState.DELIVERED_TO_PICKUP_LOCATION]: "Доставлена на місце видачі",
                [ShipmentState.DELIVERED]: "Доставлена",
                [ShipmentState.RETURNING]: "Повертається",
                [ShipmentState.RETURNED]: "Повернута",
                [ShipmentState.LOST]: "Втрачена",
                [ShipmentState.TO_BE_CANCELLED]: "Скасовується",
                [ShipmentState.CANCELLED]: "Скасована",
                [ShipmentState.UNKNOWN]: "Невідомий",
            }
        },
        report: {
            link: 'Звіт про посилку',
            title: 'Звіт про посилку',
            table: {
                external_order_task_id: 'Замовлення',
                external_order_states: 'Стан замовлення',
                order_id: 'Зовнішній номер',
                buyer_name: 'Покупець',
                shipment_state: 'Стан відправлення',
                eshop_created_at: 'Прийнято магазином',
                wms_created_at: 'Прийнято складом',
                work_started_at: 'Початок роботи',
                work_ended_at: 'Кінець роботи',
                carrier_accepted_at: 'Прийнято перевізником',
                carrier_delivered_at: 'Доставлено',
                searchHint: "Пошук за номером замовлення, зовнішнім номером та покупцем"
            }
        },
        handover: {
            link: 'Передача посилок',
            title: 'Передача посилок',

            form: {
                carrierId: "Виберіть перевізника",
                stockId: "Виберіть склад",
                subStockId: "Лише підсклад ...",
                selectShipments: "Вибрати посилку",
            },

            table: {
                created_at: "Дата створення",
                substock: {
                    id: "Підсклад"
                },
                shipment: {
                    ids: "Кількість посилок",
                    detail: "Деталь"
                },
                issueProtocol: "Вручити посилку",
                notYetIssued: "Ще не випущено"
            },

            handOverAll: "Передати всі відправлення ({0})",
            reload: "Оновити відправлення, що очікують",
            waitingForPacking: "Відправлення, що очікують на пакування: {0}",

            create: {
                title: "Виставити індивідуальний протокол",

                form: {
                    carrierId: "Виберіть перевізника",
                    stockId: "Виберіть склад",
                    subStockId: "Виберіть підсклад",
                    selectShipments: "Вибрати посилку",
                    noShipmentSelect: "Інакше роздрукує протокол для всіх посилок, які очікують на передачу",
                },
            },

            printProtocol: "Друк протоколу",
            noStockShipmentsWaiting: "Немає посилок з обраного складу, які очікують на передачу обраному перевізнику.",
            noShipmentsWithoutProtocol: "Не існує жодної посилки для передачі, яке вже не було б включено в протокол." +
                "Для створення іншого протоколу для такої посилки його потрібно вибрати вручну."
        }
    },
    images: {
        name: "Зображення",
        plural: "Зображення",
        url: "Адреса",
        main: "Головне зображення",
        setMain: "Встановити як головне",
        type: {
            name: "Тип",
            local: "місцевий",
            localLabel: "Завантажити зображення",
            external: "зовнішній",
            externalLabel: "Вставити зображення з URL"
        },
        create: {
            titleSimple: "Додати зображення",
            title: "Додати зображення до '{0}'",
            done: "Зображення додано до '{0}'",
            unableToLoad: "Не вдалося завантажити зображення"
        },
        update: {
            titleSimple: "Редагувати зображення",
            title: "Редагувати зображення '{0}'",
            done: "Зображення '{0}' відредаговано"
        },
        delete: {
            done: "Зображення видалено",
            failed: "Не вдалося видалити зображення. Будь ласка, спробуйте пізніше."
        },
        store: {
            title: "Зберегти локально",
            hint_keep_link: "Буде збережено тільки посилання на зображення",
            hint_store_link: "Зображення буде завантажено та збережено локально на нашому сервері",
            done: "Зовнішнє зображення успішно завантажено та збережено як локальне"
        }
    },
    print: {
        sent: "Надіслано на друк!",
        noConfigFor: "Друк не налаштований для {0}!",
        socketTimeout: "Помилка підключення до принтера, перевірте конфігурацію.",
        error: 'Під час друку щось пішло не так, будь ласка, перевірте результат друку.'
    },
    list: {
        loadMore: "Завантажити більше",
        loadingMore: "Завантажую додаткові",
        unableToLoad: "Не вдалося завантажити список. Будь ласка, спробуйте знову."
    },
    api: {
        401: "Користувач не ввійшов до системи!",
        403: "У вас немає доступу до цієї функції!",
        405: "Ця дія зараз не дозволена!",
        412: "Елемент було змінено іншим користувачем. Будь ласка, повторіть дію.",
        unexpectedError: "Сталася несподівана помилка. Будь ласка, спробуйте повторити дію пізніше.",
        ares: {
            status: {
                404: "Суб'єкт із вказаним ІНПП не знайдено!"
            }
        },
        barcodes: {
            unknown: "Невідомий код",
            status: {
                404: "Вказаний код не знайдено!"
            }
        },
        shipments: {
            packages: {
                status: {
                    404: "Посилку або пакунок не знайдено!",
                    409: "Пакунок неможливо видалити - посилка вже була відправлена!"
                }
            },
            protocols: {
                create: {
                    status: {
                        404: "Перевізник, підсклад або одне з відправлень не знайдено!",
                        409: "Деякі відправлення не є у статусі 'Очікує на відправлення' або не має номера доставки."
                    }
                },
                getAll: {
                    status: {
                        404: "Запланований перевізник або підсклад не знайдено!"
                    }
                },
                status: {
                    404: "Запитаний протокол не знайдено!",
                    423: "Запитаний протокол ще не готовий, будь ласка, спробуйте пізніше.",
                }
            },
            status: {
                404: "Запитане відправлення не знайдено!",
                423: "Етикетка перевізника ще не готова, будь ласка, спробуйте пізніше.",
                409: "Неможливо відправити посилку, тому що вона не знаходиться в відповідному стані."
            }
        },
        shipmentCarrier: {
            contracted: {
                all: {
                    byOwner: {
                        status: {
                            404: "Власника підскладу не знайдено!"
                        }
                    },
                    bySubStock: {
                        status: {
                            404: "Підсклад не знайдено!"
                        }
                    }
                },

                status: {
                    404: "Перевізник або власник підскладу не знайдено!",
                    409: "Не вдалося видалити перевізника зі списку договорених перевізників."
                }
            },
            status: {
                404: "Запитаний перевізник не знайдено!"
            }
        }
    },
    address: {
        street: "Вулиця",
        house_number: "Номер будинку",
        suburb: "Уточнення адреси",
        city: "Місто",
        postal_code: "Поштовий індекс",
        phone: "Телефон",
        email: "E-mail",
        country: "Країна (локалізовано)",
        country_iso_code: "ISO Країна (англійською)",
    },
    bank_account: {
        prefix_number: "Префікс",
        account_number: "Номер рахунку",
        bank_code: "Код банку",
        full_number: "Номер рахунку включаючи код банку",
        iban: "IBAN",
        swift: "SWIFT"
    },
    changes: {
        created_at: "Створено",
        created_by: "Створено",
        updated_at: "Останнє оновлення",
        updated_by: "Оновлено",
        noUpdates: "Немає наступних оновлень"
    },
    ares: {
        load: "Завантажити дані з ARES",
        validated: "Перевірено ARES",
        not_validated: "Не перевірено ARES"
    },
    print_type: {
        [PrintType.PDF]: "PDF",
        [PrintType.ZPL]: "ZPL",
        [PrintType.ZPLX]: "ZPLX",
        [PrintType.ZPLX2]: "ZPLX2",
        [PrintType.ZPLXM]: "ZPLXM",
        [PrintType.ZPL_PD]: "ZPLPD",
        [PrintType.ESCP]: "ESCP",
        [PrintType.HTML]: "HTML"
    },
    form: {
        rules: {
            mustBeHigherThanFrom: "Не повинно бути раніше, ніж 'з'!",
            mustBeUpToToday: "Не повинно бути пізніше, ніж сьогодні!",
            maxDays: "Максимум {0} день!"
        }
    },
    attributes: {
        dataTypes: {
            [AttributeDataTypes.STRING]: 'Текст',
            [AttributeDataTypes.INTEGER]: 'Номер',
            [AttributeDataTypes.FLOAT]: 'Десяткове число',
            [AttributeDataTypes.BOOLEAN]: 'Значення істини',
        },
        templates: {
            title: "Атрибути",
            titleSingle: "Атрибут",
            link: "Атрибути",
            create: "Створіть атрибут",
            update: {
                link: "Редагувати атрибут",
                title: "Відредаговано {0}"
            },
            delete: {
                usage: {
                    loading: "Виявлено використання атрибута '{0}'",
                    inUse: "Використовується атрибут '{0}', видалення якого призведе до втрати значень",
                    notInUse: "Атрибут '{0}' не використовується"
                },
                done: "Атрибут {0} видалено",
            },
            form: {
                description: 'Опис',
                value: "Ім'я",
                data_type: 'Тип даних'
            }
        }
    },
};

<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
      :overlay-opacity="0.9"
    >
      <v-img
        :src="bigImageUrl"
      />
    </v-dialog>
    <v-img
      v-if="justOneImage && singleImage"
      :src="singleImage.url"
      :lazy-src="publicPath + 'assets/product_image_placeholder.jpg'"
      class="ma-1"
      contain
      @click="showImage(singleImage.url)"
    />
    <div
      v-else-if="!justOneImage"
      class="d-flex flex-column justify-center mt-1"
    >
      <div class="d-flex flex-wrap justify-center mt-1">
        <div
          v-for="(image, index) of images"
          :key="index"
        >
          <v-img
            v-if="index < imageLimit"
            :src="image.url"
            :lazy-src="publicPath + 'assets/product_image_placeholder.jpg'"
            :aspect-ratio="1"
            max-width="28vw"
            class="grey lighten-2 ma-1"
            @click="showImage(image.url)"
          />
        </div>
      </div>
      <v-btn
        text
        color="secondary"
        class="my-2"
        @click="addImageDialog = true"
      >
        <v-icon class="mr-2">
          $addItem
        </v-icon>
        {{ $t('base.images.create.titleSimple') }}
      </v-btn>
    </div>
    <v-dialog
      v-model="addImageDialog"
      width="800"
      :overlay-opacity="0.9"
    >
      <v-card>
        <v-card-title>
          {{ $t('base.images.create.title', [productName]) }}
        </v-card-title>
        <ImageCreateComponent
          :item-name="productName"
          :item-id="productId"
          @imagesUpdated="imagesUpdated"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import {ProductAPI} from "@/api/ProductAPI";
    import ImageCreateComponent from "@/app/images/components/ImageCreate.component";
    import {Product} from "@/utils/product";

    export default {
        name: "TaskItemDetailImages",
        components: {ImageCreateComponent},
        props: {
            productId: {
                type: Number,
                default: null
            },
            productName: {
                type: String,
                default: ''
            },
            justOneImage: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            images: [],
            dialog: false,
            bigImageUrl: null,
            publicPath: process.env.BASE_URL,
            addImageDialog: false
        }),
        computed: {
            imageLimit: function () {
                return 12;
            },
            singleImage: function () {
                return Product.getSingleImage({images: this.images});
            }
        },
        createdOrActivated: function () {
            this.fetchImages();
        },
        methods: {
            fetchImages: function () {
                if (this.productId === null) {
                    return;
                }
                ProductAPI.getAllImages(this.productId)
                    .then(response => {
                        if (response.data.length) {
                            this.images = response.data;
                            this.$emit('has-image', !!this.images.length);
                        }
                    })
                    .catch(() => {
                        this.snack('products.images.unableToLoad');
                    });
            },
            showImage: function (imageUrl) {
                this.dialog = true;
                this.bigImageUrl = imageUrl;
            },
            imagesUpdated() {
                this.addImageDialog = false;
                this.fetchImages();
            }
        }
    };
</script>

<style scoped>

</style>
